import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import CommonButton from "../../../common/Button";
import SectionTitle from "../../../common/Typography/SectionTitle";
import { formatPrice } from "../../../helpers/CommonHelper";
import {
  postAutocreateRecommendation,
  postCreateRecommendation,
} from "../../../redux/features/recommendations/recommendationApi";
import { getRecommandationForReview } from "../../../redux/features/rfq/rfqApi";
import Table from "../../../common/Table";
import NavTabs from "../../../common/NavigationTab";
import FullPageLoader from "../../../common/FullPageLoader";
import { useSearchParams } from "react-router-dom";
import {
  RFQ_RECOMMENDATION_AUTO_CRITERIA,
  RFQ_RECOMMENDATION_TYPE,
} from "../../../utils/constants/constants";
import QuotationDetailsCardComponent from "../../../common/QuotationDetailsCard";
import toast from "react-hot-toast";
import { useCompanySelector } from "../../../hooks/useCompanySelector";
interface TabPanelProps {
  user: string;
  productList: any[];
  quotation?: any;
  index: number;
  value: number;
}

const columns: any = [
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Images",
    getCustomColumn: true,
    getColData: (i: any) =>
      i.images?.length > 0 ? (
        <img src={i.images[i.images.length - 1].url} className="h-10" />
      ) : (
        <img src={"https://via.placeholder.com/150x100.png"} className="h-10" />
      ),
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name / Specification",
    callBackArguments: ["id", "isItemSelected", "labelId"],
    getCustomColumn: true,
    getColData: (row: any) => `${row.name} / ${row.specification || ''}`,
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity / Unit",
    getCustomColumn: true,
    getColData: (row: any) => `${row.quantity} ${row.unit || ''}`,
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Rate(Rs.) / Tax(%)",
    getCustomColumn: true,
    getColData: (row: any) => {
      return (<>
        <span>Price: {formatPrice(row?.price) || "-"}</span><br/>
        <span>Tax: {row.tax_percentage || ""}</span><br/>
      </>)
    }
  },
  {
    id: "total_price",
    numeric: false,
    disablePadding: false,
    label: "Total Cost (Rs.)",
    getCustomColumn: true,
    getColData: (row: any) => formatPrice(row?.total_price),
  },
];

function TabPanel(props: TabPanelProps) {
  const { quotation, productList, value, index, user, ...other } = props;
  const list = productList?.map((product: any) => {
    const { name, category, quantity, specification, target_price, unit } =
      product?._rfq_product;
    return {
      ...product,
      name,
      category,
      quantity,
      specification,
      target_price,
      unit,
    };
  });
  const recommendedList = list.filter((item) => item.is_recommended);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <div className="mb-6">
            {productList?.length > 0 && (
              <>
                {quotation && (
                  <QuotationDetailsCardComponent
                    user={user}
                    quotationDetails={quotation}
                    rfqDetails={quotation?._rfq_request?._rfq}
                  />
                )}
                <div className="">
                  <div className="py-6">
                    <div className="text-right mb-3">
                      Total Cost -{" "}
                      <span className="font-semibold">
                        Rs. {formatPrice(quotation?.recommendation_total_price) || 'NA'}
                      </span>
                    </div>
                    <Table columns={columns} rows={recommendedList} actions={[]} />
                  </div>
                </div>
              </>
            )}
          </div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReviewRecommandation = (props: any) => {
  const { user, c_uuid} = useCompanySelector(props.routeKey);
  const { rfqId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [navigationTab, setNavigationTab] = useState<any>([]);
  const [allQuotations, setAllQuotations] = useState<any>([]);
  const [search, setSearch] = useSearchParams();
  const quotationId = search.get("quotationId") || "";
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    console.log(search.get("criteria"), "seee")
    if (rfqId && search.get("quotationId")) {
      setLoading(true);
      getRecommandationForReview({
        user,
        c_uuid,
        rfqId,
        type: RFQ_RECOMMENDATION_TYPE.single,
        criteria: "",
        quotationId,
      })
        .then((response) => {
          setAllQuotations(response.data);
          const tabList = response.data?.map(
            (quotation: any, index: number) => {
              return {
                label: `Quotation ${index + 1}`,
                info: { ...a11yProps(index) },
              };
            }
          );
          setNavigationTab([...tabList]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (rfqId && !quotationId) {
      setLoading(true);
      getRecommandationForReview({
        user,
        c_uuid,
        rfqId,
        type: RFQ_RECOMMENDATION_TYPE.auto,
        criteria:
          search.get("criteria") ||
          RFQ_RECOMMENDATION_AUTO_CRITERIA.lowest_total_cost,
      })
        .then((response) => {
          console.log(response);
          setAllQuotations(response.data);
          const tabList = response.data?.map(
            (quotation: any, index: number) => {
              return {
                label: `Quotation ${index + 1}`,
                info: { ...a11yProps(index) },
              };
            }
          );
          setNavigationTab([...tabList]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const generateRecommendation = () => {
    setLoading(true);
    if (rfqId && quotationId) {
      postCreateRecommendation({ user, c_uuid, rfqId, quotationId })
        .then((res: any) => {
          toast.success("Recommendation generated successfully")
          setLoading(false);
          navigate("/buyer/recommendations");
        })
        .catch(() => {
          setLoading(false);
          //navigate("/buyer/recommendations");
        });
    } else if (rfqId && !quotationId) {
      postAutocreateRecommendation({ user, c_uuid, rfqId, criteria: search.get("criteria") || RFQ_RECOMMENDATION_AUTO_CRITERIA.lowest_total_cost })
        .then((res: any) => {
          toast.success("Recommendation generated successfully")
          setLoading(false);
          navigate("/buyer/recommendations");
        })
        .catch(() => {
          setLoading(false);
          //navigate("/buyer/recommendations");
        });
    }
  };

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <SectionTitle className="mt-6 mb-1">Review Recommendations</SectionTitle>
      <p className="mb-4 text-sm text-brand-text-primary">{`${allQuotations.length} quotation(s) generated`}</p>
      <Box
        sx={{
          width: "100%",
          background: "white",
          padding: "1.5rem",
          paddingTop: "0.5rem",
          marginTop: "2rem"
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: 1,
            borderColor: "#EBEEF3",
            background: "white",
          }}
        >
          {" "}
          <NavTabs
            tabList={navigationTab}
            currentTab={value}
            onChange={handleChange}
          />
        </Box>
        {allQuotations?.map((quotation: any, index: number) => {
          return (
            <TabPanel
              key={`quotation${index}`}
              quotation={quotation}
              productList={quotation.quotation_products}
              value={value}
              index={index}
              user={user}
            />
          );
        })}
        <div className="my-8 flex justify-end">
          <p className="flex items-center text-sm font-medium text-brand-text-primary mr-3">{allQuotations.length} quotation(s) generated</p>
          <CommonButton
            text="Finalise Recommendations"
            onClick={generateRecommendation}
          />
        </div>
      </Box>
    </>
  );
};

export default ReviewRecommandation;
