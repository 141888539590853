import { createSlice } from "@reduxjs/toolkit";
import {
  createRFQ,
  getRFQ,
  getRFQTemplate,
  updateRFQ,
  getAllRFQ,
} from "./rfqApi";

const initialState: any = {
  currentRfq: {
    status: null,
    data: "idle",
  },
  rfqTemplates: {
    data: null,
    status: "idle",
  },
  allRfq: {
    data: null,
    status: "idle",
  },
};

const formatRfqData = (data: any) => {
  const add = {
    basicDetails: {
      billing_entity_id: data._billing_entity?.id,
      company_id: data.company_id,
      contact_person_contact: data.contact_person_contact,
      contact_person_email: data.contact_person_email,
      contact_person_name: data.contact_person_name,
      created_at: data.created_at,
      delivery_id: data._delivery?.id,
      description: data.description,
      id: data.id,
      release_at: data.release_at,
      stages: data.stages,
      status: data.status,
      submission_at: data.submission_at,
      template: data._template,
      terms: data.terms ?? [],
      title: data.title,
      rfq_no: data.rfq_no,
      remarks: data.remarks,
      selected_supplier_company_ids: data.selected_supplier_company_ids,
      uploaded_file_url: data?.uploaded_file_url,
      products: data.products,
    },
    billingEntity: {
      _billing_entity: data?._billing_entity,
      _delivery: data?._delivery,
    },
  };
  return add;
};
const rfqSlice = createSlice({
  initialState,
  name: "rfqSlice",
  reducers: {
    clearCurrentRfq: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getRFQTemplate.pending, (state, action) => {
      state.rfqTemplates.status = "pending";
    });
    builder.addCase(getRFQTemplate.fulfilled, (state, action) => {
      state.rfqTemplates.status = "succeeded";
      state.rfqTemplates.data = action.payload.data;
    });
    builder.addCase(getRFQTemplate.rejected, (state, action) => {
      state.rfqTemplates.status = "failed";
      state.rfqTemplates.data = action.error.message;
    });
    builder.addCase(createRFQ.pending, (state, action) => {
      state.currentRfq.status = "pending";
    });
    builder.addCase(createRFQ.fulfilled, (state, action) => {
      state.currentRfq.status = "succeeded";
      state.currentRfq.data = formatRfqData(action.payload)?.basicDetails;
      state.currentRfq.billingEntity = formatRfqData(action.payload)?.billingEntity;
    });
    builder.addCase(createRFQ.rejected, (state, action) => {
      state.currentRfq.status = "failed";
      state.currentRfq.data = action.error.message;
    });
    builder.addCase(updateRFQ.pending, (state, action) => {
      state.currentRfq.status = "pending";
    });
    builder.addCase(updateRFQ.fulfilled, (state, action) => {
      state.currentRfq.status = "succeeded";
      state.currentRfq.data = formatRfqData(action.payload)?.basicDetails;
      state.currentRfq.billingEntity = formatRfqData(action.payload)?.billingEntity;
    });
    builder.addCase(updateRFQ.rejected, (state, action) => {
      state.currentRfq.status = "failed";
      state.currentRfq.data = action.error.message;
    });
    builder.addCase(getAllRFQ.pending, (state, action) => {
      state.allRfq.status = "pending";
    });
    builder.addCase(getAllRFQ.fulfilled, (state, action) => {
      state.allRfq.status = "succeeded";
      state.allRfq.data = action.payload.data;
    });
    builder.addCase(getAllRFQ.rejected, (state, action) => {
      state.allRfq.status = "failed";
      state.allRfq.data = action.error.message;
    });
    builder.addCase(getRFQ.pending, (state, action) => {
      state.currentRfq.status = "pending";
    });
    builder.addCase(getRFQ.fulfilled, (state, action) => {
      state.currentRfq.status = "succeeded";
      state.currentRfq.data = formatRfqData(action.payload)?.basicDetails;
      state.currentRfq.billingEntity = formatRfqData(action.payload)?.billingEntity;
    });
    builder.addCase(getRFQ.rejected, (state, action) => {
      state.currentRfq.status = "failed";
      state.currentRfq.data = action.error.message;
    });
  },
});

export default rfqSlice.reducer;

export const { clearCurrentRfq } = rfqSlice.actions;
