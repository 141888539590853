import { API_URLS, GLOBAL_API_URL } from "../../../utils/constants/apiUrls";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/interceptor";

export const getSupplier = createAsyncThunk(
  "getSuppliers",
  async (data: { user: string; c_uuid: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.get(
        `${API_URLS[data.user]?.getSupplierList}?c_uuid=${data.c_uuid}`
      );

      return result;
    }
  }
);

export const addSupplier = async (data: { user: string; data: any }) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.post(
      `${API_URLS[data.user]?.addSupplier}`,
      data.data
    );
    return result;
  }
};
