import React, { useEffect, useState } from "react";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CommonModal from "../../../../common/Modal/index";
import DeleteModal from "../../../../common/Modal/DeleteModal";
import Input from "../../../../common/Input/MaterialInput";
import CommonButton from "../../../../common/Button";
import SectionTitle from "../../../../common/Typography/SectionTitle";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { addRfqTemplate } from "../../../../redux/features/company/companyApi";
import BackNavigation from "../../../../common/BackNavigation";
import { useCompanySelector } from "../../../../hooks/useCompanySelector";
import { RouteComponent } from "../../../../utils/common";
import { APP_USER_ROUTES } from "../../../../utils/constants/appRoutes";
import { useNavigate, useParams } from "react-router-dom";
import {
  getRfqTemplate,
  editRfqTemplate,
} from "../../../../redux/features/company/companyApi";
import FullPageLoader from "../../../../common/FullPageLoader";
import { InitialValue } from "../interfaces";
import { validationSchema, validationSchemaModal } from "../constants";

const AddEditRfqTemplate: React.FC<RouteComponent> = (
  props: RouteComponent
) => {
  const navigate = useNavigate();
  const { user, c_uuid } = useCompanySelector(props.routeKey);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean | number>(false);
  let [loading, setLoading] = useState(true);
  const [inputValues, setInputValues] = useState({
    name: "",
    description: "",
    terms: [
      {
        key: "Payment Terms",
        type: "text",
        label: "Payment Terms",
        placeholder: "Payment Terms",
      },
    ],
  });
  const { rfqTemplateId } = useParams();

  useEffect(() => {
    if (rfqTemplateId) {
      getRfqTemplate({ user, c_uuid, rfq_template_uuid: rfqTemplateId })
        .then((data: any) => {
          setInputValues(data);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      if (rfqTemplateId) {
        let toastId = toast.loading("Editing template");
        const data = {
          c_uuid,
          ...formik.values,
        };
        editRfqTemplate({
          user,
          c_uuid,
          rfq_template_uuid: rfqTemplateId,
          data,
        })
          .then((res: any) => {
            toast.dismiss(toastId);
            toast.success("Template updated successfully");
            formik.resetForm();
          })
          .catch((err: any) => {
            toast.dismiss(toastId);
            toast.error("Editing template failed");
            console.log(err);
          });
      } else {
        let toastId = toast.loading("Adding template");
        addRfqTemplate({ user, c_uuid, data: { c_uuid, ...values } })
          .then((res: any) => {
            toast.dismiss(toastId);
            toast.success("Template added successfully");
            formik.resetForm();
            navigate(
              `/${user}/${APP_USER_ROUTES.company}/${APP_USER_ROUTES.manageRfqTemplates}`
            );
          })
          .catch((err: any) => {
            toast.error("Adding template failed");
          });
      }
    },
  });

  const handleAdd = () => {
    setOpenModal(true);
  };

  const handleEdit = (
    e: React.SyntheticEvent,
    index: number,
    data: InitialValue
  ) => {
    e.preventDefault();
    setOpenModal(true);
    formikModal.setValues({ ...data });
    setIsEditing(index);
  };

  const handleDelete = (
    e: React.SyntheticEvent,
    index: number,
    data: { label: string; placeholder: string }
  ) => {
    e.preventDefault();
    setOpenDeleteModal(true);
    setIsEditing(index);
  };

  const onConfirmDelete = () => {
    let i: any = Number.isInteger(isEditing) ? isEditing : 0;
    formik.values.terms.splice(i, 1);
    setOpenDeleteModal(false);
    setIsEditing(false);
  };

  const formikModal = useFormik({
    initialValues: {
      key: "",
      type: "text",
      label: "",
      placeholder: "",
    },
    validationSchema: validationSchemaModal,
    onSubmit: (values: any, { resetForm }) => {
      // check if the same key exis previously
      const a = [...formik.values.terms].reduce((acc, cum) => {
        return {
          ...acc,
          [cum.key]: {
            ...cum,
          },
          type: "text",
        };
      }, {});
      if (a[values.key] && !Number.isInteger(isEditing)) {
        toast.error("Please select a different key. This key already exist.");
        setOpenModal(true);
        return;
      } else {
        const date = Date.now();
        values.key = `${values.label}${date}`;
        if (Number.isInteger(isEditing)) {
          const id: any = isEditing;
          formik.values.terms?.splice(id, 1, values);
        } else {
          formik.values.terms.push(values);
        }
        resetForm();
        setOpenModal(false);
        setIsEditing(false);
      }
    },
  });

  useEffect(() => {
    if (!formikModal.isValid && !formikModal.isSubmitting) {
      toast.dismiss();
      toast.error("Please provide the required fields");
    }
  }, [formikModal.isSubmitting]);

  const onClickAgree = (e: any) => {
    e.preventDefault();
    formikModal.handleSubmit();
  };

  if (loading) {
    return <FullPageLoader />;
  }

  const addEditModalContent = (
    <div className="flex flex-col">
      {/* <Input
        name="key"
        label="Key (Internal Name - Not visible in the RFQ Page)"
        placeholder="Key for the field (Internal use)"
        value={formikModal?.values?.key}
        onChange={formikModal.handleChange}
        validationKey={formikModal.touched.key && formikModal.errors.key}
        /> */}

      <Input
        name="label"
        label="Label"
        placeholder="Label for the field"
        value={formikModal?.values?.label}
        onChange={formikModal.handleChange}
        validationKey={formikModal.touched.label && formikModal.errors.label}
      />

      <Input
        name="placeholder"
        label="Placeholder"
        placeholder="Placeholder for the field"
        value={formikModal?.values?.placeholder}
        onChange={formikModal.handleChange}
        validationKey={
          formikModal.touched.placeholder && formikModal.errors.placeholder
        }
      />
    </div>
  );
  const val = formik.values.terms;
  return (
    <>
      <div className="my-6">
        <div className="flex justify-between my-6">
          <div className="flex  justify-between space-x-2 pb-6">
            <BackNavigation />
            <SectionTitle className="">{`${
              rfqTemplateId ? "Edit" : "Add"
            } RFQ Template`}</SectionTitle>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <form onSubmit={formik.handleSubmit}>
          <p className="font-semibold">Template Details</p>
          <div className="grid grid-cols-2 gap-2 mt-2">
            <Input
              name="name"
              label="Template Name"
              value={formik?.values?.name}
              onChange={formik.handleChange}
              validationKey={formik.touched.name && formik.errors.name}
              // disabled={isDisabled}
            />

            <Input
              name="description"
              label="Template Description"
              value={formik?.values?.description}
              onChange={formik.handleChange}
              validationKey={
                formik.touched.description && formik.errors.description
              }
              //  disabled={isDisabled}
            />
          </div>
          <div className="my-2 mt-16">
            <div className="flex justify-between items-center">
              <div>
                <p className="font-semibold">Template Fields</p>
                <p className="text-xs font-brand-light-gray mt-1">
                  Note: You need to click on "Save Template" to save the
                  template after editing or deleting fields
                </p>
              </div>
              <div>
                <CommonButton
                  text="Add Field"
                  onClick={handleAdd}
                ></CommonButton>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2 my-2">
              {val?.map((addedField: any, id: number) => {
                return (
                  <div className="flex  justify-end  items-end" key={id}>
                    <Input
                      style="col-span-2"
                      name={addedField.key}
                      label={addedField.label}
                      value={""}
                      placeholder={addedField?.placeholder}
                      disabled={true}
                    />

                    <CreateOutlinedIcon
                      className="ml-4 p-2 text-brand-primary-blue border-2 border-brand-extra-divider rounded-sm cursor-pointer "
                      sx={{ fontSize: 42 }}
                      onClick={(e) => handleEdit(e, id, addedField)}
                    />

                    <DeleteOutlineIcon
                      className="mx-2 p-2 text-brand-primary-red border-2  border-brand-extra-divider rounded-sm cursor-pointer"
                      sx={{ fontSize: 42 }}
                      onClick={(e) => handleDelete(e, id, addedField)}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="grid place-content-end">
            <CommonButton className="my-6" type="submit" text="Save Template" />
          </div>
        </form>

        {openModal && (
          <CommonModal
            open={openModal}
            headingText={
              Number.isInteger(isEditing) ? "Edit Field" : "Add Field"
            }
            setOpen={setOpenModal}
            content={addEditModalContent}
            onClickAgree={onClickAgree}
            onClickClose={() => {
              formikModal.resetForm();
            }}
            agreeButtonText={"Save"}
          />
        )}

        {openDeleteModal && (
          <DeleteModal
            open={openDeleteModal}
            headingText={"Delete Fields"}
            setOpen={setOpenDeleteModal}
            content={"Are you sure you want to delete this field ?"}
            agreeButtonText={"Yes"}
            cancelButtonText={"No"}
            onClickAgree={onConfirmDelete}
          />
        )}
      </div>
    </>
  );
};

export default AddEditRfqTemplate;
