import AuthHelpers from "../../helpers/AuthHelpers";
import { APP_USER_ROUTES } from "../../utils/constants/appRoutes";
import {
  AdminRole,
  BuyerRole,
  PLATFORM_USERS,
  SupplierRole,
} from "../../utils/constants/constants";
import { ReactComponent as DashboardIcon } from "../../assets/img/sidebar/dashboard.svg";
import { ReactComponent as RFQIcon } from "../../assets/img/sidebar/rfq.svg";
import { ReactComponent as RecommendationIcon } from "../../assets/img/sidebar/recommendations.svg";
import { ReactComponent as SupplierIcon } from "../../assets/img/sidebar/suppliers.svg";
import { ReactComponent as CompanyIcon } from "../../assets/img/sidebar/company.svg";
import { ReactComponent as PrifileIcon } from "../../assets/img/sidebar/settings.svg";

export const sideBarConfig = [
  {
    id: "dashboard",
    drawerItemName: "Dashboard",
    icon: DashboardIcon,
    allowedUserType: [
      PLATFORM_USERS.ADMIN,
      PLATFORM_USERS.BUYER,
      PLATFORM_USERS.SUPPLIER,
    ],
    allowedUsers: [
      AdminRole.admin,
      BuyerRole.purchaseAdmin,
      BuyerRole.purchaser,
      BuyerRole.approver,
      SupplierRole.sellerAdmin,
      SupplierRole.user,
    ],
    link: AuthHelpers.getUserTypeFromLocalStorage()
      ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
          APP_USER_ROUTES.dashboard
        }`
      : "",
  },
  {
    id: "rfqs",
    drawerItemName: "RFQs",
    icon: RFQIcon,
    allowedUserType: [PLATFORM_USERS.ADMIN, PLATFORM_USERS.BUYER],
    allowedUsers: [
      AdminRole.admin,
      BuyerRole.purchaseAdmin,
      BuyerRole.purchaser,
    ],
    link: AuthHelpers.getUserTypeFromLocalStorage()
      ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${APP_USER_ROUTES.rfq}`
      : "",
  },
  {
    id: "recommendations",
    drawerItemName: "Recommendations",
    icon: RecommendationIcon,
    allowedUserType: [
      PLATFORM_USERS.BUYER],
    allowedUsers: [
      BuyerRole.purchaseAdmin,
      BuyerRole.purchaser,
    ],
    link: AuthHelpers.getUserTypeFromLocalStorage()
      ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/recommendations`
      : "",
  },
  {
    id: "pending-approvals",
    drawerItemName: "Pending Approvals",
    icon: RFQIcon,
    allowedUserType: [PLATFORM_USERS.BUYER],
    allowedUsers: [BuyerRole.approver],
    link: AuthHelpers.getUserTypeFromLocalStorage()
      ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/pending-approvals`
      : "",
  },
  {
    id: "rfqs-supplier",
    drawerItemName: "RFQs",
    icon: RFQIcon,
    allowedUserType: [PLATFORM_USERS.SUPPLIER],
    allowedUsers: [SupplierRole.sellerAdmin, SupplierRole.user],
    link: AuthHelpers.getUserTypeFromLocalStorage()
      ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${APP_USER_ROUTES.rfq}`
      : "",
  },
  {
    id: "suppliers",
    drawerItemName: "Suppliers",
    icon: SupplierIcon,
    allowedUserType: [PLATFORM_USERS.BUYER],
    allowedUsers: [
      AdminRole.admin,
      BuyerRole.purchaseAdmin,
      BuyerRole.purchaser,
    ],
    link: AuthHelpers.getUserTypeFromLocalStorage()
          ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
              APP_USER_ROUTES.suppliers
            }/${APP_USER_ROUTES.allSuppliers}`
          : "",
  },
  {
    id: "buyers",
    drawerItemName: "Buyers",
    icon: SupplierIcon,
    allowedUserType: [PLATFORM_USERS.ADMIN, PLATFORM_USERS.BUYER],
    allowedUsers: [
      AdminRole.admin,
    ],
    link: AuthHelpers.getUserTypeFromLocalStorage()
      ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
          APP_USER_ROUTES.buyers
        }`
      : "",
    // subItems: [
    //   {
    //     id: "all-buyers",
    //     drawerItemName: "All Buyers",
    //     allowedUserType: [PLATFORM_USERS.ADMIN],
    //     allowedUsers: [
    //       AdminRole.admin,
    //     ],
    //     link: AuthHelpers.getUserTypeFromLocalStorage()
    //       ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
    //           APP_USER_ROUTES.suppliers
    //         }/${APP_USER_ROUTES.allSuppliers}`
    //       : "",
    //   },
    // ],
  },
  {
    id: "suppliers",
    drawerItemName: "Suppliers",
    icon: SupplierIcon,
    allowedUserType: [PLATFORM_USERS.ADMIN],
    allowedUsers: [
      AdminRole.admin,
    ],
    link: AuthHelpers.getUserTypeFromLocalStorage()
      ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
          APP_USER_ROUTES.suppliers
        }`
      : "",
    // subItems: [
    //   {
    //     id: "all-suppliers",
    //     drawerItemName: "All Buyers",
    //     allowedUserType: [PLATFORM_USERS.ADMIN],
    //     allowedUsers: [
    //       AdminRole.admin,
    //     ],
    //     link: AuthHelpers.getUserTypeFromLocalStorage()
    //       ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
    //           APP_USER_ROUTES.suppliers
    //         }/${APP_USER_ROUTES.allSuppliers}`
    //       : "",
    //   },
    // ],
  },
  {
    id: "company",
    drawerItemName: "Company",
    icon: CompanyIcon,
    allowedUserType: [
      PLATFORM_USERS.BUYER,
      PLATFORM_USERS.SUPPLIER,
    ],
    allowedUsers: [
      BuyerRole.purchaseAdmin,
      BuyerRole.purchaser,
      SupplierRole.sellerAdmin,
      SupplierRole.user,
    ],
    link: AuthHelpers.getUserTypeFromLocalStorage()
      ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
          APP_USER_ROUTES.company
        }`
      : "",
    subItems: [
      {
        id: "company-details",
        drawerItemName: "Company Details",
        allowedUserType: [
          PLATFORM_USERS.BUYER,
          PLATFORM_USERS.SUPPLIER,
        ],
        allowedUsers: [
          BuyerRole.purchaser,
          SupplierRole.sellerAdmin,
          SupplierRole.user,
        ],
        link: AuthHelpers.getUserTypeFromLocalStorage()
          ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
              APP_USER_ROUTES.company
            }/${APP_USER_ROUTES.companyDetails}`
          : "",
      },
      {
        id: "company-users",
        drawerItemName: "Manage Users",
        allowedUsers: [
          BuyerRole.purchaseAdmin,
          BuyerRole.purchaser,
          SupplierRole.sellerAdmin,
          SupplierRole.user,
        ],
        allowedUserType: [
          PLATFORM_USERS.BUYER,
          PLATFORM_USERS.SUPPLIER,
        ],
        link: AuthHelpers.getUserTypeFromLocalStorage()
          ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
              APP_USER_ROUTES.company
            }/${APP_USER_ROUTES.companyUser}`
          : "",
      },
      {
        id: "company-billing-entities",
        drawerItemName: "Billing Entities",
        allowedUserType: [PLATFORM_USERS.BUYER],
        allowedUsers: [
          BuyerRole.purchaseAdmin,
          BuyerRole.purchaser,
        ],
        link: AuthHelpers.getUserTypeFromLocalStorage()
          ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
              APP_USER_ROUTES.company
            }/${APP_USER_ROUTES.companyBilling}`
          : "",
      },
      {
        id: "all-delivery-addresses",
        drawerItemName: "Delivery Addresses",
        allowedUserType: [PLATFORM_USERS.ADMIN, PLATFORM_USERS.BUYER],
        allowedUsers: [
          AdminRole.admin,
          BuyerRole.purchaseAdmin,
          BuyerRole.purchaser,
        ],
        link: AuthHelpers.getUserTypeFromLocalStorage()
          ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
              APP_USER_ROUTES.company
            }/${APP_USER_ROUTES.allDeliveryAddresses}`
          : "",
      },
      {
        id: "manage-rfq-templates",
        drawerItemName: "RFQ Templates",
        allowedUserType: [PLATFORM_USERS.ADMIN, PLATFORM_USERS.BUYER],
        allowedUsers: [
          AdminRole.admin,
          BuyerRole.purchaseAdmin,
          BuyerRole.purchaser,
        ],
        link: AuthHelpers.getUserTypeFromLocalStorage()
          ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
              APP_USER_ROUTES.company
            }/${APP_USER_ROUTES.manageRfqTemplates}`
          : "",
      },
    ],
  },
  {
    id: "profile",
    drawerItemName: "Profile",
    icon: PrifileIcon,
    allowedUserType: [
      PLATFORM_USERS.BUYER,
      PLATFORM_USERS.SUPPLIER,
    ],
    allowedUsers: [
      AdminRole.admin,
      BuyerRole.approver,
      BuyerRole.purchaseAdmin,
      BuyerRole.purchaser,
      SupplierRole.sellerAdmin,
      SupplierRole.user,
    ],
    link: AuthHelpers.getUserTypeFromLocalStorage()
      ? `/${AuthHelpers.getUserTypeFromLocalStorage()}/${
          APP_USER_ROUTES.profile
        }`
      : "",
  },
];
