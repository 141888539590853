import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonMaterialInput from "../../../common/Input/MaterialInput";
import FullPageLoader from "../../../common/FullPageLoader";
import PageTitle from "../../../common/Typography/PageTitle";
import Table from "../../../common/Table";
import {
  getRecommendationList,
  sendToSupplier,
  updateRecommendation,
} from "../../../redux/features/recommendations/recommendationApi";
import { STATUS_JSX } from "../../../utils/constants/status";
import CommonModal from "../../../common/Modal";
import {
  API_CONSTANTS,
  BuyerRole,
  DATETIME_FORMAT_STRINGS,
  LOADING_TEXT,
  RFQ_RECOMMENDATION_STATUS,
} from "../../../utils/constants/constants";
import { getCompanyUsers } from "../../../redux/features/company/companyApi";
import toast from "react-hot-toast";
import { APP_USER_URLS } from "../../../utils/constants/appUrls";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import CommonLink from "../../../common/CommonLink";
import { downloadRecommendationQuotation } from "../../../redux/features/rfq/rfqApi";
import { AppDispatch } from "../../../redux/store";
import { RouteComponent } from "../../../utils/common";

const Recommendations: React.FC<RouteComponent> = (props: RouteComponent) => {
  const user: string = props.routeKey;
  const companySelector = useSelector((state: any) => state.company);
  const companyLocalStorage = localStorage.getItem("company");
  const parsedLocalStorage =
    companyLocalStorage && JSON.parse(companyLocalStorage);
  const c_uuid: string = companySelector.company.id || parsedLocalStorage.id;
  const [reccomandations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openApproversModel, setOpenApproversModel] = useState(false);
  const [openQuotationsModal, setOpenQuotationsModal] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState<any>({});
  const [companyUserList, setCompanyUserList] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [meta, setMeta] = useState({});
  const [query, setQuery] = useState<any>({
    c_uuid,
    page: 1,
    limit: 10,
    sort: "",
    sortBy: "",
    filter: "",
  });
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const getComanyUsersList = (user: string, c_uuid: string) => {
    dispatch(getCompanyUsers({ user, c_uuid }));
  };
  useEffect(() => {
    getComanyUsersList(user, c_uuid);
    getRecommendationList({ user, c_uuid, query }).then((response: any) => {
      setRecommendations(response.data);
      setMeta(response.meta);
      setLoading(false);
    });
  }, [query]);
  useEffect(() => {
    if (companySelector.users.status === API_CONSTANTS.success) {
      const approvers = companySelector?.users?.data?.data.filter((item:any) => item?.role?.name === BuyerRole.approver);
      setCompanyUserList(approvers);
    }
  }, [companySelector]);
  if (loading) {
    return <FullPageLoader />;
  }

  const onPageQueryChange = (page?: number, limit?: number) => {
    setQuery({ ...query, page, limit });
  };
  const onSortQueryChange = (sort?: string, sortBy?: string) => {
    setQuery({ ...query, sort, sortBy });
  };

  const handleRecommendationQuotationDownload = (
    user: any,
    c_uuid: string,
    rfqId: string,
    recommendationId: string,
    quotationId: string
  ) => {
    let toastId = toast.loading(LOADING_TEXT.processing);
    rfqId &&
      recommendationId &&
      quotationId &&
      downloadRecommendationQuotation({
        user,
        c_uuid,
        rfqId,
        recommendationId,
        quotationId,
      })
        .then(() => {
          toast.dismiss(toastId);
          toast.success("Recommendation Downloaded successfully");
        })
        .catch((e: any) => {
          console.log(e);
          toast.dismiss(toastId);
          toast.error("Some error occured");
        });
  };

  const sendForApproval = (row: any) => {
    updateRecommendation({
      user,
      c_uuid,
      rfqId: selectedRow.rfq.id,
      quotationId: selectedRow.id,
      data: { approvals: [row.id] },
    }).then(() => {
      toast.success("Your request has been sent to Approver.");
    });
  };
  const openApprovalModal = (row: any) => {
    setOpenApproversModel(true);
    setSelectedRow(row);
  };

  const openRecommendationQuotationsModal = (row: any) => {
    setOpenQuotationsModal(true);
    setSelectedRecommendation(row);
  };

  const sendToSupplierFunc = (row: any) => {
    sendToSupplier({
      user,
      c_uuid,
      rfqId: row.rfq?.id,
      recommendationId: row.id,
      data: {
        supplier_company_id:
          row?.rfq_quotation?._rfq_request.issued_to_company?.id,
      },
    }).then((res) => {
      toast.success("Successfully sent to supplier(s)");
    });
  };

  const columnsUsers = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Approver Name",
      //callBackArguments: ["id", "isItemSelected", "labelId"],
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Approver Email",
      maxWidth: '300px'
    },
    {
      id: "view",
      label: "Send for Approval",
      callbackArguments: ["id"],
      getCustomColumn: true,
      getColData: (row: any) => (
        <button
          className="text-brand-primary-blue font-semibold text-sm"
          onClick={() => sendForApproval(row)}
        >
          Send for Approval
        </button>
      ),
    },
  ];

  const quotationTableColumns = [
    {
      id: "quotation_no",
      numeric: false,
      disablePadding: false,
      label: "Quotation No.",
    },
    {
      id: "view",
      label: "Products Count",
      callbackArguments: ["id"],
      disablePadding: false,
      getCustomColumn: true,
      getColData: (row: any) =>
        row.quotation_products.filter((item: any) => item.is_recommended)
          .length,
    },
    {
      id: "view",
      label: "Download PDF",
      callbackArguments: ["id"],
      disablePadding: false,
      getCustomColumn: true,
      getColData: (row: any) => (
        <CommonLink
          text={"Download"}
          onClick={() =>
            handleRecommendationQuotationDownload(
              user,
              c_uuid,
              selectedRecommendation.rfq.id,
              selectedRecommendation.id,
              row.id
            )
          }
        />
      ),
    },
  ];

  const tableColumns = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "RFQ NO.",
      callBackArguments: ["id"],
      getCustomColumn: true,
      getColData: (row: any) => (
        <Link
          to={`${APP_USER_URLS.rfqViewDetailsBuyer(row.rfq.id)}`}
          className="underline underline-offset-4 font-semibold"
        >
          {row.rfq.rfq_no}
        </Link>
      ),
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      getCustomColumn: true,
      getColData: (row: any) => STATUS_JSX[row.status],
      label: "Status",
    },
    {
      id: "download",
      numeric: false,
      disablePadding: false,
      label: "Download PDF",
      getCustomColumn: true,
      getColData: (row: any) => (
        <CommonLink
          text={"Download"}
          onClick={() => openRecommendationQuotationsModal(row)}
        />
      ),
    },
    {
      id: "quotations_count",
      label: "No. of Quotations",
      numeric: false,
      disablePadding: false,
      getCustomColumn: true,
      getColData: (row: any) => row?.quotations?.length || 0,
    },
    {
      id: "sent_for_approval",
      label: "Sent for approval",
      callbackArguments: ["id"],
      getCustomColumn: true,
      getColData: (row: any) => row.sent_for_approval  ? "Yes" : 'No',
      // getCustomColumn: true,
      // getColData: (row: any) => (
      //   <button
      //     className="text-brand-primary-blue font-semibold text-sm"
      //     onClick={() => openApprovalModal(row)}
      //   >
      //     Send for Approval
      //   </button>
      // ),
    },
    {
      id: "submission_at",
      numeric: false,
      disablePadding: false,
      getCustomColumn: true,
      label: "Dates",
      maxWidth: '400px',
      getColData: (row: any) =>(
        <>
        <p className="text-xs">Created on: {moment(row.created_at).format(DATETIME_FORMAT_STRINGS.STANDARD_DATE_STRING)}</p>
        <p className="text-xs">Last Submission Date: {row?.rfq.submission_at
          ? moment(row?.rfq?.submission_at).format(DATETIME_FORMAT_STRINGS.STANDARD_DATE_STRING)
          : "NA"}</p>
        <p className="text-xs">Approved on: {row.approved_on ? moment(row.approved_on).format(DATETIME_FORMAT_STRINGS.STANDARD_DATE_STRING) : "NA"}</p>
        </>
      ),
    },
  ];

  const recommendationActions = [
    {
      id: "view",
      label: "Send for approval",
      disabled: (row: any) => row.status !== RFQ_RECOMMENDATION_STATUS.pending,
      callbackArguments: [],
      onClick: (row: any) => {
        openApprovalModal(row);
      },
    },
    {
      id: "view",
      label: "Compare quotations",
      callbackArguments: [],
      onClick: (row: any) => {
        navigate(APP_USER_URLS.buyerComapreRfq(row.rfq.id));
      },
    },
    {
      id: "view",
      label: "Send to Supplier(s)",
      disabled: (row: any) => row.status !== RFQ_RECOMMENDATION_STATUS.approved,
      callbackArguments: [],
      onClick: (row: any) => {
        sendToSupplierFunc(row);
      },
    },
  ];

  return (
    <>
      <PageTitle className="py-2 mb-2">Recommendations</PageTitle>
      {/* <div className="py-3">
        <CommonMaterialInput
          placeholder="Search RFQ No"
          searchIcon
          style="w-1/2"
        />
      </div> */}
      <Table
        columns={tableColumns}
        rows={reccomandations}
        actions={recommendationActions}
        image={require("../../../assets/img/rfq/datagrid.svg").default}
        headingText={"No Recommendations found"}
        description={"You don't have any recommendations yet"}
        meta={meta}
        onPageChange={onPageQueryChange}
        onSortChange={onSortQueryChange}
      />
      <CommonModal
        open={openApproversModel}
        setOpen={setOpenApproversModel}
        content={
          <div className="w-full mt-4">
            <Table
              columns={columnsUsers}
              rows={companyUserList}
              actions={[]}
              image={require("../../../assets/img/rfq/datagrid.svg").default}
              headingText={"No Approver Found"}
              description={"You don't have any approver"}
              text={"Add User"}
              onClickFunc={() => navigate(APP_USER_URLS.buyerManageUser)}
            />
          </div>
        }
        headingText={"Send for Approval"}
        onClickAgree={() => alert()}
        maxWidth={"md"}
      />

      <CommonModal
        open={openQuotationsModal}
        setOpen={setOpenQuotationsModal}
        content={
          <div className="w-full mt-4">
            <Table
              columns={quotationTableColumns}
              rows={selectedRecommendation?.quotations}
              actions={[]}
            />
          </div>
        }
        headingText={"Download Recommendations"}
        maxWidth={"md"}
      />
    </>
  );
};

export default Recommendations;
