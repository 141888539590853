import { createSlice } from "@reduxjs/toolkit";
import { getAllSupplierRfq } from "./supplierRfqApi";
const initialState: any = {
  allRfq: {
    status: "idle",
    data: null,
  },
};

const supplierModuleSlice = createSlice({
  initialState,
  name: "supplierRfqSlice",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllSupplierRfq.pending, (state, action) => {
      state.allRfq.status = "pending";
    });
    builder.addCase(getAllSupplierRfq.fulfilled, (state, action) => {
      state.allRfq.status = "succeeded";
      state.allRfq.data = action.payload?.data;
    });
    builder.addCase(getAllSupplierRfq.rejected, (state, action) => {
      state.allRfq.status = "failed";
      state.allRfq.data = action.error.message;
    });
  },
});

export default supplierModuleSlice.reducer;
