import { APP_USER_ROUTES } from "./appRoutes";
import { RFQ_RECOMMENDATION_AUTO_CRITERIA, RFQ_RECOMMENDATION_TYPE } from "./constants";

export const APP_USER_URLS = {
  viewBillingEntity: (id: string) =>
    `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.company}/${APP_USER_ROUTES.companyBilling}/${APP_USER_ROUTES.billingEntity}/${id}`,
  editBillingEntity: (id: string) =>
    `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.company}/${APP_USER_ROUTES.companyBilling}/${APP_USER_ROUTES.editCompanyBillingEntities}/${id}`,
  rfqEditBuyer: (id: string) =>
    `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.rfq}/${id}/${APP_USER_ROUTES.editRfq}`,
  rfqViewDetailsBuyer: (id: string) =>
    `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.rfq}/${id}/view/${APP_USER_ROUTES.details}`,
  rfqViewSupplierRequestsBuyer: (id: string) =>
    `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.rfq}/${id}/view/${APP_USER_ROUTES.requests}`,
  rfqViewSupplierQuotationsBuyer: (id: string) =>
    `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.rfq}/${id}/view/${APP_USER_ROUTES.quotation}`,
  rfqViewDetailsSupplier: (id: string) =>
    `/${APP_USER_ROUTES.supplier}/${APP_USER_ROUTES.rfq}/${APP_USER_ROUTES.viewDetail}/${id}`,
  viewQuotation: (requestId: string, quotationId: string) =>
    `/${APP_USER_ROUTES.supplier}/${APP_USER_ROUTES.rfq}/${APP_USER_ROUTES.viewDetail}/${APP_USER_ROUTES.quotation}/${requestId}/${quotationId}`,
  editQuotation: (requestId: string, quotationId: string) =>
    `/${APP_USER_ROUTES.supplier}/${APP_USER_ROUTES.rfq}/${APP_USER_ROUTES.viewDetail}/${requestId}/${quotationId}`,
  supplierQuotationHistory: (requestId: string) =>
    `/${APP_USER_ROUTES.supplier}/${APP_USER_ROUTES.rfq}/${requestId}/${APP_USER_ROUTES.allQuotations}`,
  buyerQuotationHistory: (rfqId: string, requestId: string) =>
    `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.rfq}/${rfqId}/request/${requestId}/${APP_USER_ROUTES.allQuotations}`,
  buyerQuotationDetail: (
    rfqId: string,
    requestId: string,
    quotationId: string
  ) =>
    `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.rfq}/${rfqId}/request/${requestId}/${APP_USER_ROUTES.quotation}/${quotationId}`,
  buyerComapreRfq: (rfqId: string) =>
    `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.rfq}/${rfqId}/compare`,
  buyerPreviewRecommendation: (rfqId?: string, quotationId?:string, type = RFQ_RECOMMENDATION_TYPE.auto , criteria = RFQ_RECOMMENDATION_AUTO_CRITERIA.lowest_total_cost) =>
    `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.rfq}/${rfqId}/review-recommendations?type=${type}&criteria=${criteria}${quotationId ? `&quotationId=${quotationId}` : `` }`,
  buyerAddSupplier: `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.suppliers}/${APP_USER_ROUTES.allSuppliers}`,
  buyerBillingEntities: `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.company}/${APP_USER_ROUTES.companyBilling}`,
  buyerDeliveryAddresses: `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.company}/${APP_USER_ROUTES.allDeliveryAddresses}`,
  buyerManageUser: `/${APP_USER_ROUTES.buyer}/${APP_USER_ROUTES.company}/${APP_USER_ROUTES.companyUser}`,
};
