import { API_URLS } from "../../../utils/constants/apiUrls";
import { RFQ_RECOMMENDATION_AUTO_CRITERIA } from "../../../utils/constants/constants";
import axiosInstance from "../../../utils/interceptor";

export const getRecommendationList = async (data: {
  user: string;
  c_uuid: string;
  query: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      API_URLS[data.user].getRecommendationList,
      { params: { ...data.query } }
    );
    return result;
  }
};

export const getRecommendationListApprovals = async (data: {
  user: string;
  c_uuid: string;
  query: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      API_URLS[data.user].getRecommendationListApprovers,
      { params: { ...data.query } }
    );
    return result;
  }
};

export const postAutocreateRecommendation = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
  criteria?: string;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.post(
      API_URLS[data.user].autocreateRecommendation(data.rfqId),
      { c_uuid: data.c_uuid, criteria: data.criteria || RFQ_RECOMMENDATION_AUTO_CRITERIA.lowest_total_cost  }
    );
    return result;
  }
};

export const postCreateRecommendation = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
  quotationId: string;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.post(
      API_URLS[data.user].createRecommendation(data.rfqId, data.quotationId),
      { c_uuid: data.c_uuid }
    );
    return result;
  }
};

export const updateRecommendation = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
  quotationId: string;
  data: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.put(
      API_URLS[data.user].updateRecommendation(data.rfqId, data.quotationId),
      { c_uuid: data.c_uuid, ...data.data }
    );
    return result;
  }
};

export const postApproveRecommendation = async (data: {
  user: string;
  c_uuid: string;
  recommendationId: string;
  data: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.put(
      API_URLS[data.user].acceptRecommendation(data.recommendationId),
      { c_uuid: data.c_uuid, ...data.data }
    );
    return result;
  }
};

export const sendToSupplier = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
  recommendationId: string;
  data: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.post(
      API_URLS[data.user].sendToSupplier(data.rfqId, data.recommendationId),
      { c_uuid: data.c_uuid, ...data.data }
    );
    return result;
  }
};
