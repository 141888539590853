import React, { useEffect, useState } from "react";
import PageTitle from "../../../common/Typography/PageTitle";
import { useCompanySelector } from "../../../hooks/useCompanySelector";
import { getDashBoardData } from "../../../redux/features/rfq/rfqApi";
import { RouteComponent } from "../../../utils/common";
import Buyers from "../AllBuyers";
import AllSuppliers from "../AllSuppliers";
import rfqLogo from "../../../assets/img/sidebar/dashboard-blue.svg";
import {
    TaskAltOutlined,
    CancelOutlined,
    AddOutlined,
  } from "@mui/icons-material"

const Dashboard = (props: RouteComponent) => {
    const [data, setData] = useState<any>({})
  const { user, c_uuid } = useCompanySelector(props.routeKey);
  const iconSx = {
    color: "#286EF1",
    fontSize: 40,
    p: 1,
    m: 2,
    bgcolor: "#EDF6FF",
    borderRadius: "2px",
  };
  useEffect(() => {
    getDashBoardData({ user, c_uuid })
      .then(() => {})
      .catch(() => {});
  });
  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      
    <div className="rfqDetails flex w-full my-6 hidden">
      <div className="detail w-3/12  mr-3  flex items-center  bg-white rounded ">
        {/* <LocalMallOutlined sx={{ ...iconSx }} /> */}
        <img
          src={rfqLogo}
          alt="rfq logo"
          className="bg-brand-background-skyblue p-2.5 m-4 rounded-sm"
        />
        <div className=" ">
          <h1 className="text-2xl font-semibold">{data?.buyers}</h1>
          <p className="text-sm text-brand-primary-gray">Total Buyers</p>
        </div>
      </div>
      <div className="detail w-3/12 mx-3  flex items-center  bg-white rounded">
        <TaskAltOutlined
          sx={{ ...iconSx, color: "#00BC4B", bgcolor: "#E4FFEF" }}
        />
        <div className="">
          <h1 className="text-2xl font-semibold">{data?.confirmed_buyers}</h1>
          <p className="text-sm text-brand-primary-gray">Confirmed Buyers</p>
        </div>
      </div>
      <div className="detail w-3/12 mx-3  flex items-center  bg-white rounded">
        <CancelOutlined
          sx={{ ...iconSx, color: "#FF3C31", bgcolor: "#FFEFEF" }}
        />
        <div className="">
          <h1 className="text-2xl font-semibold">{data?.unapproved_buyers}</h1>
          <p className="text-sm text-brand-primary-gray">Unapproved Buyers</p>
        </div>
      </div>
      <div className="detail w-3/12 ml-3 flex items-center  bg-white rounded">
        <AddOutlined sx={{ ...iconSx, color: "#286EF1", bgcolor: "#EDF6FF" }} />
        <div className="">
          <h1 className="text-2xl font-semibold">{data?.invited_buyers}</h1>
          <p className="text-sm text-brand-primary-gray">Invited Buyers</p>
        </div>
      </div>
    </div>

    <div className="rfqDetails flex w-full my-6 hidden">
      <div className="detail w-3/12  mr-3  flex items-center  bg-white rounded ">
        {/* <LocalMallOutlined sx={{ ...iconSx }} /> */}
        <img
          src={rfqLogo}
          alt="rfq logo"
          className="bg-brand-background-skyblue p-2.5 m-4 rounded-sm"
        />
        <div className=" ">
          <h1 className="text-2xl font-semibold">{data?.suppliers}</h1>
          <p className="text-sm text-brand-primary-gray">Total Suppliers</p>
        </div>
      </div>
      <div className="detail w-3/12 mx-3  flex items-center  bg-white rounded">
        <TaskAltOutlined
          sx={{ ...iconSx, color: "#00BC4B", bgcolor: "#E4FFEF" }}
        />
        <div className="">
          <h1 className="text-2xl font-semibold">{data?.confirmed_suppliers}</h1>
          <p className="text-sm text-brand-primary-gray">Confirmed Suppliers</p>
        </div>
      </div>
      <div className="detail w-3/12 mx-3  flex items-center  bg-white rounded">
        <CancelOutlined
          sx={{ ...iconSx, color: "#FF3C31", bgcolor: "#FFEFEF" }}
        />
        <div className="">
          <h1 className="text-2xl font-semibold">{data?.verified_suppliers}</h1>
          <p className="text-sm text-brand-primary-gray">Verified Suppliers</p>
        </div>
      </div>
      <div className="detail w-3/12 ml-3 flex items-center  bg-white rounded">
        <AddOutlined sx={{ ...iconSx, color: "#286EF1", bgcolor: "#EDF6FF" }} />
        <div className="">
          <h1 className="text-2xl font-semibold">{data?.invited_suppliers}</h1>
          <p className="text-sm text-brand-primary-gray">Invited Supplies</p>
        </div>
      </div>
    </div>
  
      <Buyers routeKey={props.routeKey} page="dashboard" />
      <AllSuppliers routeKey={props.routeKey} page="dashboard" />
    </>
  );
};
export default Dashboard;
