import React from "react";
import AppLogoImage from "../../assets/img/logo/buydesk.svg";
import AppLogoSmall from '../../assets/img/logo/buydesk-small.svg';

const AppLogo = (props: any) => {
  const { small, className } = props;
  return (
    <div className={`${className}`}>
      <img src={small ? AppLogoSmall : AppLogoImage} alt="logo" className="inline-block" />
    </div>
  );
};

export default AppLogo;
