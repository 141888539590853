import React from "react";
function SectionTitle({ children, fontSize, className }: any) {
  return (
    <h2
      style={{ fontSize: fontSize }}
      className={
        "font-semibold text-brand-text-title text-xl" + " " + className
      }
    >
      {children}
    </h2>
  );
}
export default SectionTitle;
