export const APP_USER_ROUTES = {
  main: "/",
  login: "login",
  register: "register",
  unauthorised: "unauthorised",
  forgotPassword: "forgot-password",
  resetPassword: "reset-password",
  //users
  admin: "system_admin",
  buyer: "buyer",
  supplier: "supplier",
  //sidebar
  suppliers: "suppliers",
  allSuppliers: "all-suppliers",
  allRfq: "rfqs",
  createRfq: "create",
  rfqDetail: "rfq-details",
  createRfqIndex: "create-rfq",
  editRfq: "edit",
  dashboard: "dashboard",
  rfq: "rfq",
  rfqs: "rfqs",
  buyers: "buyers",
  rfqRequest: "rfq-request",
  quotation: "quotations",
  requests: "requests",
  allQuotations: "all-quotations",
  company: "company",
  companyBilling: "billing-entities",
  createCompanyBillingEntities: "create-billing-entities",
  editCompanyBillingEntities: "edit-billing-entities",
  billingEntity: "billing-entity",
  companyDetails: "details",
  companyUser: "users",
  manageRfqTemplates: "manage-rfq-templates",
  addRfqTemplates: "add-rfq-templates",
  editRfqTemplates: "edit-rfq-templates",
  allDeliveryAddresses: "all-delivery-addresses",
  profile: "profile",
  inviteUser: "invitations/user",
  compareQuotation: "compare",
  viewDetail: "view-detail",
  details: "details",
  rolePermission: 'roles-and-permission',
  addrole: 'add-role',
  notFound: "*",
};
