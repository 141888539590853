import React, { lazy } from "react";
import { Outlet } from "react-router";
import { APP_USER_ROUTES } from "../utils/constants/appRoutes";
import { Navigate } from "react-router";
import Landing from "../containers/Home";
import NotFound from "../containers/NotFound";
import { PLATFORM_USERS } from "../utils/constants/constants";
import ResetPassword from "../containers/Auth/components/ResetPassword";

import AddRfqTemplate from "../containers/Buyer/ManageRfqTemplates/AddEditRfqTemplate";
import PendingApproval from "../containers/Buyer/PendingApprovals";
import Recommendations from "../containers/Buyer/Recommendations";
import ReviewRecommandation from "../containers/Buyer/ReviewRecommandation";
import RolePermissions from "../containers/Buyer/RolePermission";
import Dashboard from "../containers/Admin/Dashboard";
//import ManageRolesPermession from "../containers/Buyer/ManageRolePermission";
const Unautherised = lazy(() => import("../containers/NotFound/403page"));
const BillingEntities = lazy(
  () => import("../containers/Buyer/BillingEntities")
);
const BuyerManageUsers = lazy(() => import("../containers/Buyer/ManageUsers"));
const BuyerCompanyDetails = lazy(
  () => import("../containers/Buyer/CompanyDetails")
);
const SupplierManageUsers = lazy(
  () => import("../containers/Supplier/ManageUsers")
);
const SupplierCompanyDetails = lazy(
  () => import("../containers/Supplier/CompanyDetails")
);
const ManageRfqTemplates = lazy(
  () => import("../containers/Buyer/ManageRfqTemplates")
);
const AllDeliveryAddresses = lazy(
  () => import("../containers/Buyer/DeliveryAddresses/AllDeliveryAddresses")
);
const BillingEntity = lazy(
  () => import("../containers/Buyer/BillingEntities/ViewBillingEntity")
);
const ComparePricing = lazy(() => import("../containers/Buyer/Pricing"));
const InvitedUser = lazy(() => import("../containers/InvitedUsers"));
const Auth = lazy(() => import("../containers/Auth"));
const Login = lazy(() => import("../containers/Auth/components/Login"));
const Register = lazy(() => import("../containers/Auth/components/Register"));
//const Admin = lazy(() => import("../archieve/layouts/Admin"));
const ForgotPassword = lazy(
  () => import("../containers/Auth/components/ForgotPassword")
);
const CompanyIdentity = React.lazy(
  () => import("../containers/Auth/components/CompanyIdentity")
);
const Buyer = React.lazy(() => import("../containers/Buyer"));
//Buyer Route
const BuyerCompany = React.lazy(() => import("../containers/Buyer/Company"));
const SupplierCompany = React.lazy(
  () => import("../containers/Supplier/Company")
);

const BuyerDashboard = React.lazy(
  () => import("../containers/Buyer/Dashboard")
);
const SupplierDashboard = React.lazy(
  () => import("../containers/Supplier/Dashboard")
);
const BuyerRfqs = React.lazy(() => import("../containers/Buyer/Rfqs"));
const SupplierRfqs = React.lazy(() => import("../containers/Supplier/Rfqs"));
const CreateRFQ = React.lazy(() => import("../containers/Buyer/CreateRFQ"));
const SuppliersList = React.lazy(
  () => import("../containers/Buyer/ManageSuppliers")
);

const BuyerProfile = React.lazy(() => import("../containers/Buyer/Profile"));

const CreateBillingEntities = React.lazy(
  () => import("../containers/Buyer/BillingEntities/AddEditBillingEntities")
);
const SupplierRfqDetails = React.lazy(
  () => import("../containers/Supplier/RfqDetails")
);
const SupplierEditQuotation = React.lazy(
  () => import("../containers/Supplier/EditQuotation")
);
const SupplierViewQuotation = lazy(
  () => import("../containers/Supplier/ViewQuotation")
);

const BuyerRfqDetail = React.lazy(
  () => import("../containers/Buyer/RfqDetail")
);
const SupplierQuotationHistory = lazy(
  () => import("../containers/Supplier/QuotationHistory")
);
const BuyerQuotationHistory = lazy(
  () => import("../containers/Buyer/QuotationHistory")
);
const BuyerViewQuotation = lazy(
  () => import("../containers/Buyer/ViewQuotation")
);
const SupplierProfile = lazy(() => import("../containers/Supplier/Profile"));

//Admin routes

const Buyers = lazy(() => import("../containers/Admin/AllBuyers"));
const AdminBuyersCompanyHeader = lazy(
  () => import("../containers/Admin/AllBuyers/Header")
);
const AdminBuyersCompanyDetails = lazy(
  () => import("../containers/Admin/AllBuyers/CompanyDetails")
);
const AdminBuyersCompanyUsers = lazy(
  () => import("../containers/Admin/AllBuyers/Users")
);
const AdminBuyersCompanySuppliers = lazy(
  () => import("../containers/Admin/AllBuyers/Suppliers")
);
const AdminBuyersCompanyRFQs = lazy(
  () => import("../containers/Admin/AllBuyers/RFQs")
);

const AdminSuppliers = lazy(() => import("../containers/Admin/AdminSuppliers"));
const AllSuppliers = lazy(() => import("../containers/Admin/AllSuppliers"))
const AdminSuppliersCompanyHeader = lazy(
  () => import("../containers/Admin/AdminSuppliers/Header")
);
const AdminSuppliersCompanyDetails = lazy(
  () => import("../containers/Admin/AdminSuppliers/CompanyDetails")
);
const AdminSuppliersCompanyUsers = lazy(
  () => import("../containers/Admin/AdminSuppliers/Users")
);
const AdminSuppliersCompanyRFQs = lazy(
  () => import("../containers/Admin/AdminSuppliers/RFQs")
);

const AdminRfq = lazy(() => import("../containers/Admin/AdminRFQs"));
const AdminRFQHeader = lazy(() => import("../containers/Admin/AdminRFQs/Header"));
const AdminRFQDetails = lazy(
  () => import("../containers/Admin/AdminRFQs/RFQDetails")
);
const AdminRFQSupplierQuotations = lazy(
  () => import("../containers/Admin/AdminRFQs/SupplierQuotations")
);
const AdminRFQSupplierRequests = lazy(
  () => import("../containers/Admin/AdminRFQs/SupplierRequests")
);

//TODO - Write Hook

/** Config meaning
 *
 * path - path for url
 * element - component, add routekey as a props to check user for that component
 * index is it index route
 * indexElement - if index routes then what's is the route element. <Outlet/> in that component is mandatory
 * chidlren : to define nested route
 */
const AdminModule = {
  path: APP_USER_ROUTES.admin,
  element: <Outlet />,
  index: true,
  routeKey: PLATFORM_USERS.ADMIN,
  indexElement: (
    <Auth>
      <CompanyIdentity
        action={"Login"}
        link={APP_USER_ROUTES.login}
        routeKey={PLATFORM_USERS.ADMIN}
      />
    </Auth>
  ),
  children: [
    {
      path: APP_USER_ROUTES.register,
      element: (
        <Auth>
          <Register routeKey={PLATFORM_USERS.ADMIN} />
        </Auth>
      ),
      routeKey: PLATFORM_USERS.ADMIN,
    },
    {
      path: `${APP_USER_ROUTES.login}`,
      routeKey: PLATFORM_USERS.ADMIN,
      element: (
        <Auth>
          <CompanyIdentity
            action={"Login"}
            link={APP_USER_ROUTES.login}
            routeKey={PLATFORM_USERS.ADMIN}
          />
        </Auth>
      ),
    },
    {
      path: `:slug/${APP_USER_ROUTES.login}`,
      routeKey: PLATFORM_USERS.ADMIN,
      element: (
        <Auth>
          <Login routeKey={PLATFORM_USERS.ADMIN} />
        </Auth>
      ),
    },
    {
      path: APP_USER_ROUTES.dashboard,
      element: <Dashboard routeKey={PLATFORM_USERS.ADMIN} />,
      protected: true,
      routeKey: PLATFORM_USERS.ADMIN,
    },
    {
      path: APP_USER_ROUTES.suppliers,
      element: <AllSuppliers routeKey={PLATFORM_USERS.ADMIN} />,
      protected: true,
      routeKey: PLATFORM_USERS.ADMIN,
    },
    {
      path: APP_USER_ROUTES.buyers,
      element: <Outlet />,
      indexElement: <Buyers routeKey={PLATFORM_USERS.ADMIN} />,
      protected: true,
      routeKey: PLATFORM_USERS.ADMIN,
      index: true,
      children: [
        {
          path: `:buyerId`,
          element: <Outlet />,
          indexElement: <Navigate to={APP_USER_ROUTES.details} replace />,
          protected: true,
          index: true,
          routeKey: PLATFORM_USERS.ADMIN,
          children: [
            {
              path: APP_USER_ROUTES.details,
              element: (
                <AdminBuyersCompanyHeader routeKey={PLATFORM_USERS.ADMIN}>
                  <AdminBuyersCompanyDetails routeKey={PLATFORM_USERS.ADMIN} />
                </AdminBuyersCompanyHeader>
              ),
              protected: true,
              routeKey: PLATFORM_USERS.ADMIN,
            },
            {
              path: APP_USER_ROUTES.companyUser,
              element: (
                <AdminBuyersCompanyHeader routeKey={PLATFORM_USERS.ADMIN}>
                  <AdminBuyersCompanyUsers routeKey={PLATFORM_USERS.ADMIN} />
                </AdminBuyersCompanyHeader>
              ),
              protected: true,
              routeKey: PLATFORM_USERS.ADMIN,
            },
            {
              path: APP_USER_ROUTES.suppliers,
              element: (
                <AdminBuyersCompanyHeader routeKey={PLATFORM_USERS.ADMIN}>
                  <AdminBuyersCompanySuppliers
                    routeKey={PLATFORM_USERS.ADMIN}
                  />
                </AdminBuyersCompanyHeader>
              ),
              protected: true,
              routeKey: PLATFORM_USERS.ADMIN,
            },
            {
              path: APP_USER_ROUTES.rfqs,
              element: (
                <AdminBuyersCompanyHeader routeKey={PLATFORM_USERS.ADMIN}>
                  <AdminBuyersCompanyRFQs routeKey={PLATFORM_USERS.ADMIN} />
                </AdminBuyersCompanyHeader>
              ),
              protected: true,
              routeKey: PLATFORM_USERS.ADMIN,
            },
          ],
        },
      ],
    },
    {
      path: `${APP_USER_ROUTES.suppliers}/:supplierId`,
      element: <Outlet />,
      indexElement: <Navigate to={APP_USER_ROUTES.details} replace />,
      protected: true,
      index: true,
      routeKey: PLATFORM_USERS.ADMIN,

      children: [
        {
          path: `${APP_USER_ROUTES.details}`,
          element: (
            <AdminSuppliersCompanyHeader routeKey={PLATFORM_USERS.ADMIN}>
              <AdminSuppliersCompanyDetails routeKey={PLATFORM_USERS.ADMIN} />
            </AdminSuppliersCompanyHeader>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.ADMIN,
        },
        {
          path: `${APP_USER_ROUTES.companyUser}`,
          element: (
            <AdminSuppliersCompanyHeader routeKey={PLATFORM_USERS.ADMIN}>
              <AdminSuppliersCompanyUsers routeKey={PLATFORM_USERS.ADMIN}/>
            </AdminSuppliersCompanyHeader>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.ADMIN,
        },
        {
          path: `${APP_USER_ROUTES.allQuotations}`,
          element: (
            <AdminSuppliersCompanyHeader routeKey={PLATFORM_USERS.ADMIN}>
              <AdminSuppliersCompanyRFQs routeKey={PLATFORM_USERS.ADMIN} />
            </AdminSuppliersCompanyHeader>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.ADMIN,
        },
      ],
    },
    {
      path: `${APP_USER_ROUTES.rfq}`,
      element: <AdminRfq routeKey={PLATFORM_USERS.ADMIN} />,
      protected: true,
      routeKey: PLATFORM_USERS.ADMIN,
      children: [],
    },
    {
      path: `${APP_USER_ROUTES.rfq}/:rfqId/view`,
      element: <Outlet />,
      index: true,
      indexElement: <Navigate to={APP_USER_ROUTES.details} replace />,
      protected: true,
      routeKey: PLATFORM_USERS.ADMIN,
      children: [
        {
          path: `${APP_USER_ROUTES.details}`,
          element: (
            <AdminRFQHeader routeKey={PLATFORM_USERS.ADMIN}>
              <AdminRFQDetails routeKey={PLATFORM_USERS.ADMIN}/>
            </AdminRFQHeader>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.ADMIN,
        },
        {
          path: `${APP_USER_ROUTES.requests}`,
          element: (
            <AdminRFQHeader routeKey={PLATFORM_USERS.ADMIN}>
              <AdminRFQSupplierRequests routeKey={PLATFORM_USERS.ADMIN}/>
            </AdminRFQHeader>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.ADMIN,
        },
        {
          path: `${APP_USER_ROUTES.quotation}`,
          element: (
            <AdminRFQHeader routeKey={PLATFORM_USERS.ADMIN}>
              <AdminRFQSupplierQuotations routeKey={PLATFORM_USERS.ADMIN}/>
            </AdminRFQHeader>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.ADMIN,
        },
      ],
    },
  ],
};

const BuyerModule = {
  path: APP_USER_ROUTES.buyer,
  routeKey: PLATFORM_USERS.BUYER,
  element: <Outlet />,
  index: true,
  indexElement: (
    <Auth>
      <CompanyIdentity
        action={"Login"}
        link={APP_USER_ROUTES.login}
        routeKey={PLATFORM_USERS.BUYER}
      />
    </Auth>
  ),
  children: [
    {
      path: APP_USER_ROUTES.register,
      routeKey: PLATFORM_USERS.BUYER,
      element: (
        <Auth>
          <Register routeKey={PLATFORM_USERS.BUYER} />
        </Auth>
      ),
    },
    {
      path: APP_USER_ROUTES.login,
      routeKey: PLATFORM_USERS.BUYER,
      element: (
        <Auth>
          <CompanyIdentity
            action={"Login"}
            link={APP_USER_ROUTES.login}
            routeKey={PLATFORM_USERS.BUYER}
          />
        </Auth>
      ),
    },
    {
      path: APP_USER_ROUTES.forgotPassword,
      routeKey: PLATFORM_USERS.BUYER,
      element: (
        <Auth>
          <ForgotPassword routeKey={PLATFORM_USERS.BUYER} />,
        </Auth>
      ),
    },
    {
      path: APP_USER_ROUTES.resetPassword,
      routeKey: PLATFORM_USERS.BUYER,
      element: (
        <Auth>
          <ResetPassword routeKey={PLATFORM_USERS.BUYER} />
        </Auth>
      ),
    },
    {
      path: `:slug/${APP_USER_ROUTES.login}`,
      routeKey: PLATFORM_USERS.BUYER,
      element: (
        <Auth>
          <Login routeKey={PLATFORM_USERS.BUYER} />
        </Auth>
      ),
    },
    {
      path: APP_USER_ROUTES.dashboard,
      element: <BuyerDashboard  routeKey={PLATFORM_USERS.BUYER} />,
      protected: true,
      routeKey: PLATFORM_USERS.BUYER,
    },
    {
      path: "recommendations",
      element: <Recommendations routeKey={PLATFORM_USERS.BUYER} />,
      protected: true,
      routeKey: PLATFORM_USERS.BUYER,
    },
    {
      path: "pending-approvals",
      element: <PendingApproval routeKey={PLATFORM_USERS.BUYER} />,
      protected: true,
      routeKey: PLATFORM_USERS.BUYER,
    },
    {
      path: APP_USER_ROUTES.rfq,
      element: <Outlet />,
      protected: true,
      routeKey: PLATFORM_USERS.BUYER,
      index: true,
      indexElement: <BuyerRfqs routeKey={PLATFORM_USERS.BUYER} />,
      children: [
        {
          path: `:rfqId/review-recommendations`,
          element: <ReviewRecommandation routeKey={PLATFORM_USERS.BUYER} />,
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `${APP_USER_ROUTES.createRfqIndex}`,
          element: <CreateRFQ routeKey={PLATFORM_USERS.BUYER} />,
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `${APP_USER_ROUTES.createRfq}/:rfqId`,
          element: <CreateRFQ routeKey={PLATFORM_USERS.BUYER} />,
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `:rfqId/view/${APP_USER_ROUTES.details}`,
          element: (
            <BuyerRfqDetail
              routeKey={PLATFORM_USERS.BUYER}
              children={"rfqDetail"}
            />
          ),
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `:rfqId/view/${APP_USER_ROUTES.quotation}`,
          element: (
            <BuyerRfqDetail
              routeKey={PLATFORM_USERS.BUYER}
              children={"quotation"}
            />
          ),
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `:rfqId/view/${APP_USER_ROUTES.requests}`,
          element: (
            <BuyerRfqDetail
              routeKey={PLATFORM_USERS.BUYER}
              children={"supplierRequests"}
            />
          ),
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `:rfqId/${APP_USER_ROUTES.editRfq}`,
          element: <CreateRFQ routeKey={PLATFORM_USERS.BUYER} />,
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `:rfqId/request/:requestId/${APP_USER_ROUTES.quotation}/:quotationId`,
          element: <BuyerViewQuotation routeKey={PLATFORM_USERS.BUYER} />,
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `:rfqId/request/:requestId/${APP_USER_ROUTES.allQuotations}`,
          element: <BuyerQuotationHistory routeKey={PLATFORM_USERS.BUYER} />,
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `:rfqId/${APP_USER_ROUTES.compareQuotation}`,
          element: <ComparePricing routeKey={PLATFORM_USERS.BUYER} />,
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
      ],
    },
    {
      path: APP_USER_ROUTES.suppliers,
      element: <Outlet />,
      protected: true,
      routeKey: PLATFORM_USERS.BUYER,
      index: true,
      indexElement: <Navigate to={APP_USER_ROUTES.allSuppliers} replace />,
      children: [
        {
          path: `${APP_USER_ROUTES.allSuppliers}`,
          element: <SuppliersList routeKey={PLATFORM_USERS.BUYER} />,
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
      ],
    },
    {
      path: APP_USER_ROUTES.company,
      protected: true,
      routeKey: PLATFORM_USERS.BUYER,
      element: <Outlet />,
      index: true,
      indexElement: <Navigate to={APP_USER_ROUTES.companyDetails} replace />,
      children: [
        {
          path: `${APP_USER_ROUTES.companyBilling}/${APP_USER_ROUTES.createCompanyBillingEntities}`,
          element: <CreateBillingEntities routeKey={PLATFORM_USERS.BUYER} />,
          protected: true,
          exact: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `${APP_USER_ROUTES.companyBilling}/${APP_USER_ROUTES.billingEntity}/:billingEntityId`,
          element: <BillingEntity routeKey={PLATFORM_USERS.BUYER} />,
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `${APP_USER_ROUTES.companyBilling}/${APP_USER_ROUTES.editCompanyBillingEntities}/:billingEntityId`,
          element: <CreateBillingEntities routeKey={PLATFORM_USERS.BUYER} />,
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `${APP_USER_ROUTES.companyBilling}`,
          element: (
            <BuyerCompany>
              <BillingEntities routeKey={PLATFORM_USERS.BUYER} />
            </BuyerCompany>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `${APP_USER_ROUTES.companyDetails}`,
          element: (
            <BuyerCompany>
              <BuyerCompanyDetails routeKey={PLATFORM_USERS.BUYER} />
            </BuyerCompany>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `${APP_USER_ROUTES.manageRfqTemplates}`,
          element: (
            <BuyerCompany>
              <ManageRfqTemplates routeKey={PLATFORM_USERS.BUYER} />
            </BuyerCompany>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `${APP_USER_ROUTES.rolePermission}`,
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
          element: (
            <BuyerCompany>
              <RolePermissions routeKey={PLATFORM_USERS.BUYER} />
            </BuyerCompany>
          ),
        },
        {
          path: `${APP_USER_ROUTES.manageRfqTemplates}/${APP_USER_ROUTES.addRfqTemplates}`,
          element: (
            <BuyerCompany>
              <AddRfqTemplate routeKey={PLATFORM_USERS.BUYER} />
            </BuyerCompany>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },

        {
          path: `${APP_USER_ROUTES.manageRfqTemplates}/${APP_USER_ROUTES.editRfqTemplates}/:rfqTemplateId`,
          element: (
            <BuyerCompany>
              <AddRfqTemplate routeKey={PLATFORM_USERS.BUYER} />
            </BuyerCompany>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },

        {
          path: `${APP_USER_ROUTES.allDeliveryAddresses}`,
          element: (
            <BuyerCompany>
              <AllDeliveryAddresses routeKey={PLATFORM_USERS.BUYER} />
            </BuyerCompany>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
        {
          path: `${APP_USER_ROUTES.companyUser}`,
          element: (
            <BuyerCompany>
              <BuyerManageUsers routeKey={PLATFORM_USERS.BUYER} />
            </BuyerCompany>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.BUYER,
        },
      ],
    },
    {
      path: APP_USER_ROUTES.profile,
      element: <BuyerProfile routeKey={PLATFORM_USERS.BUYER} />,
      protected: true,
      routeKey: PLATFORM_USERS.BUYER,
    },
  ],
};

const SupplierModule = {
  path: APP_USER_ROUTES.supplier,
  element: <Outlet />,
  index: true,
  indexElement: (
    <Auth>
      <CompanyIdentity
        action={"Login"}
        link={`${APP_USER_ROUTES.login}`}
        routeKey={PLATFORM_USERS.SUPPLIER}
      />
    </Auth>
  ),
  children: [
    {
      path: APP_USER_ROUTES.register,
      routeKey: PLATFORM_USERS.SUPPLIER,
      element: (
        <Auth>
          <Register routeKey={PLATFORM_USERS.SUPPLIER} />
        </Auth>
      ),
    },
    {
      path: APP_USER_ROUTES.login,
      routeKey: PLATFORM_USERS.SUPPLIER,
      element: (
        <Auth>
          <CompanyIdentity
            action={"Login"}
            link={APP_USER_ROUTES.login}
            routeKey={PLATFORM_USERS.SUPPLIER}
          />
        </Auth>
      ),
    },
    {
      path: APP_USER_ROUTES.forgotPassword,
      routeKey: PLATFORM_USERS.SUPPLIER,
      element: (
        <Auth>
          <ForgotPassword routeKey={PLATFORM_USERS.SUPPLIER} />,
        </Auth>
      ),
    },
    {
      path: APP_USER_ROUTES.resetPassword,
      routeKey: PLATFORM_USERS.SUPPLIER,
      element: (
        <Auth>
          <ResetPassword routeKey={PLATFORM_USERS.SUPPLIER} />
        </Auth>
      ),
    },
    {
      path: `:slug/${APP_USER_ROUTES.login}`,
      routeKey: PLATFORM_USERS.SUPPLIER,
      element: (
        <Auth>
          <Login routeKey={PLATFORM_USERS.SUPPLIER} />
        </Auth>
      ),
    },
    {
      path: APP_USER_ROUTES.dashboard,
      element: <SupplierDashboard routeKey={PLATFORM_USERS.SUPPLIER} />,
      protected: true,
      routeKey: PLATFORM_USERS.SUPPLIER,
    },
    {
      path: APP_USER_ROUTES.rfq,
      element: <Outlet />,
      protected: true,
      routeKey: PLATFORM_USERS.SUPPLIER,
      index: true,
      indexElement: <SupplierRfqs routeKey={PLATFORM_USERS.SUPPLIER} />,
      children: [
        {
          path: `${APP_USER_ROUTES.viewDetail}/:requestId`,
          element: <SupplierRfqDetails routeKey={PLATFORM_USERS.SUPPLIER} />,
          protected: true,
          routeKey: PLATFORM_USERS.SUPPLIER,
        },
        {
          path: `${APP_USER_ROUTES.viewDetail}/:requestId/:quotationId`,
          element: <SupplierEditQuotation routeKey={PLATFORM_USERS.SUPPLIER} />,
          protected: true,
          routeKey: PLATFORM_USERS.SUPPLIER,
        },
        {
          path: `${APP_USER_ROUTES.viewDetail}/${APP_USER_ROUTES.quotation}/:requestId/:quotationId`,
          element: <SupplierViewQuotation routeKey={PLATFORM_USERS.SUPPLIER} />,
          protected: true,
          routeKey: PLATFORM_USERS.SUPPLIER,
        },
        {
          path: `:requestId/${APP_USER_ROUTES.allQuotations}`,
          element: (
            <SupplierQuotationHistory routeKey={PLATFORM_USERS.SUPPLIER} />
          ),
          protected: true,
          routeKey: PLATFORM_USERS.SUPPLIER,
        },
        {
          path: `:rfqId/${APP_USER_ROUTES.compareQuotation}`,
          element: (
            <SupplierQuotationHistory routeKey={PLATFORM_USERS.SUPPLIER} />
          ),
          protected: true,
          routeKey: PLATFORM_USERS.SUPPLIER,
        },
      ],
    },
    {
      path: APP_USER_ROUTES.company,
      protected: true,
      routeKey: PLATFORM_USERS.SUPPLIER,
      element: <Outlet />,
      index: true,
      indexElement: <Navigate to={APP_USER_ROUTES.companyDetails} replace />,
      children: [
        {
          path: `${APP_USER_ROUTES.companyDetails}`,
          element: (
            <SupplierCompany>
              <SupplierCompanyDetails routeKey={PLATFORM_USERS.SUPPLIER} />
            </SupplierCompany>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.SUPPLIER,
        },
        {
          path: `${APP_USER_ROUTES.companyUser}`,
          element: (
            <SupplierCompany>
              <SupplierManageUsers routeKey={PLATFORM_USERS.SUPPLIER} />
            </SupplierCompany>
          ),
          protected: true,
          routeKey: PLATFORM_USERS.SUPPLIER,
        },
      ],
    },
    {
      path: APP_USER_ROUTES.profile,
      element: <SupplierProfile routeKey={PLATFORM_USERS.SUPPLIER} />,
      protected: true,
      routeKey: PLATFORM_USERS.SUPPLIER,
    },
  ],
};

export const routes: any[] = [
  { path: "/", element: <Navigate to="/buyer" replace/>, redirectSkip: true },
  AdminModule,
  BuyerModule,
  SupplierModule,
  {
    path: APP_USER_ROUTES.inviteUser,
    element: <InvitedUser />,
    redirectSkip: true,
  },
  {
    path: APP_USER_ROUTES.unauthorised,
    element: <Unautherised />,
    redirectSkip: true,
  },
  { path: APP_USER_ROUTES.notFound, element: <NotFound />, redirectSkip: true },
];
