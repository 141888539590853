import React, {  useState } from "react";
import EnhancedTable from "../../../common/Table";
import PageTitle from "../../../common/Typography/PageTitle";
import { Link } from "react-router-dom";
import FullPageLoader from "../../../common/FullPageLoader";
import { useCompanySelector } from "../../../hooks/useCompanySelector";
import { useGetAllSuppliersQuery } from "../../../redux/features/paginationApis";
import moment from "moment";
import { RouteComponent } from "../../../utils/common";
import SectionTitle from "../../../common/Typography/SectionTitle";
import { generateLoginUrl, getCityDetails } from "../../../helpers/CommonHelper";
import { DATETIME_FORMAT_STRINGS, PLATFORM_USERS } from "../../../utils/constants/constants";
//import { getByuerSuppliers } from "../../../redux/features/admin/adminApi";

interface ISuppliers extends RouteComponent {
  page?: string;
}
const Suppliers = (props: ISuppliers) => {
  const isDashboard = props.page === "dashboard";
  const { user, c_uuid } = useCompanySelector(props.routeKey);
  const [query, setQuery] = useState<any>({
    c_uuid,
    page: 1,
    limit: isDashboard ? 5 : 10,
    sort: "",
    sortBy: "",
    filter: "",
  });
  const { data, isLoading } = useGetAllSuppliersQuery(
    { ...query, user },
    { refetchOnMountOrArgChange: true }
  );

  const columns = [
    {
      id: "company_name",
      numeric: false,
      disablePadding: false,
      label: "Company Details",
      callBackArguments: ["id", "isItemSelected", "labelId"],
      getCustomColumn: true,
      getColData: (row: any) => {
        return (<>
        <Link
            to={`${row.id}/details`}
            className="underline underline-offset-4 font-semibold text-md"
          >{row.name}</Link>
        <p className="text-xs pt-2">URL: { generateLoginUrl(PLATFORM_USERS.SUPPLIER, `${row?.slug || ""}/login`)}</p>
        </>)
      },
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Contact Details",
      callBackArguments: ["id", "isItemSelected", "labelId"],
      getCustomColumn: true,
      getColData: (row: any) => {
        return (<>
        Name: {row?._owner?.name}
        <br/>
        Email: {row?._owner?.email}
        <br/>
        Contact No.: {row?._owner?.contact_number}
        </>)
      },
    },
    {
      id: "Location",
      numeric: false,
      disablePadding: false,
      label: "Location",
      getCustomColumn: true,
      getColData: (row: any) => {
        return (<>
       {row?.company?._city ? getCityDetails(row?.company)?.getInfo: "NA"}
        </>)
      },
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      label: "Added On",
      getCustomColumn: true,
      getColData: (row: any) => {
        return moment(row.created_at).format(DATETIME_FORMAT_STRINGS.STANDARD_DATE_STRING);
      },
    },
  ];

  const onPageQueryChange = (page?: number, limit?: number) => {
    page && limit && setQuery({ ...query, page, limit });
  };
  const onSortQueryChange = (sort?: string, sortBy?: string) => {
    sortBy && sort && setQuery({ ...query, sort, sortBy });
  };
  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <div className="flex justify-between mb-8">
        {isDashboard ? (
          <SectionTitle>New Suppliers</SectionTitle>
        ) : (
          <PageTitle className="">All Suppliers</PageTitle>
        )}
      </div>
      <div className="pb-8">
        <EnhancedTable
          columns={columns}
          rows={data?.data || []}
          actions={[]}
          {...(isDashboard ? {} : { meta: data?.meta })}
          onPageChange={onPageQueryChange}
          onSortChange={onSortQueryChange}
          image={require("../../../assets/img/rfq/datagrid.svg").default}
          headingText={"No Suppliers"}
          description={"You don't have any Supplier yet"}
          text={"Create Supliers"}
        />
      </div>
    </>
  );
};

export default Suppliers;
