import React from "react";
import { TextField, Box, InputAdornment } from "@mui/material";
import { useCommonStyles } from "../../styles/common";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SearchIcon from "@mui/icons-material/Search";

interface Props {
  name?: string;
  value?: string | number;
  onChange?: any;
  onBlur?: any;
  label?: string;
  validationKey?: any;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  rupee?: boolean;
  searchIcon?: boolean;
  style?: string;
  multiline?: boolean;
  rows?: number;
  numberGap?: number;
}

const CommonMaterialInput: React.FC<Props> = (props) => {
  const classes = useCommonStyles();
  const {
    name,
    value,
    onChange,
    label,
    validationKey,
    type,
    placeholder,
    disabled,
    rupee,
    searchIcon,
    onBlur,
    multiline,
    rows,
    numberGap,
  } = props;
  return (
    <div
      className={`mt-4 flex justify-start w-full flex-col ${
        props?.style ?? ""
      }`}
    >
      {label && (
        <span className="text-brand-text-primary font-medium text-sm mb-1">
          {label}
        </span>
      )}
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
          fontFamily: classes.fontFamily,
        }}
        className="flex"
      >
        <TextField
          fullWidth
          type={type}
          placeholder={placeholder}
          value={value || ""}
          name={name}
          multiline={multiline}
          rows={rows}
          className={classes.inputDisabled}
          onChange={(e: any) => onChange(e)}
          onBlur={(e: any) => (onBlur ? onBlur(e) : void 0)}
          InputProps={{
            className: `${classes.bg_white} ${classes.p3px} 
              ${classes.pr0} ${classes.pl0} ${classes.fontSizeSmall} ${
              !multiline ? classes.inputHeight : ""
            } ring-0 ring-offset-0`,
            startAdornment:
              (rupee && (
                <InputAdornment position="start">
                  <CurrencyRupeeIcon
                    fontSize="small"
                    className={classes.mlpt5}
                  />
                </InputAdornment>
              )) ||
              (searchIcon && (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" className={classes.mlpt5} />
                </InputAdornment>
              )),
            inputProps:
              type === "number" ? { min: 0, step: numberGap || 0.01 } : {},
            sx: {
              fontFamily: classes.fontFamily,
              "&:hover": {
                border: "1px solid #0D4DC6",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "0px solid",
              },
              "&:focus-within": {
                border: "1px solid #0D4DC6",
              },
              "&:focus-within .MuiOutlinedInput-notchedOutline": {
                border: "0px solid",
              },
            },
          }}
          sx={{
            // width: "75%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "2px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #EBEEF3",
            },
            "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #EBEEF3 !important",
            },
            "& input": {
              padding: "7.5px 14px",
              paddingLeft: "9px",
              fontSize: "14px",
            },
            "& textarea": {
              padding: "7.5px 14px",
              paddingLeft: "9px",
              fontSize: "14px",
            },
          }}
          disabled={disabled}
          helperText={validationKey}
          error={validationKey ? true : false}
          FormHelperTextProps={{
            className: classes.ml0,
            style: { marginLeft: "0" },
          }}
        />
      </Box>
    </div>
  );
};

export default CommonMaterialInput;
