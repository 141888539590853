import * as yup from "yup";
export const validationSchema = yup.object().shape({
  name: yup.string().nullable().required("Required"),
  description: yup.string().nullable().required("Required"),
});

export const validationSchemaModal = yup.object().shape({
    key: yup.string().nullable(),
    type: yup.string().nullable().required("Required"),
    label: yup.string().nullable().required("Required"),
    placeholder: yup.string().nullable(),
  });
