import { useState } from "react";
import { useDispatch } from "react-redux";
import EnhancedTable from "../../../common/Table";
import PageTitle from "../../../common/Typography/PageTitle";
import { Link } from "react-router-dom";
import { approveBuyer } from "../../../redux/features/admin/adminApi";
import FullPageLoader from "../../../common/FullPageLoader";
import { AppDispatch } from "../../../redux/store";
import { RouteComponent } from "../../../utils/common";
import { useCompanySelector } from "../../../hooks/useCompanySelector";
import { useGetBuyersQuery } from "../../../redux/features/paginationApis";
import SectionTitle from "../../../common/Typography/SectionTitle";
import { generateLoginUrl, getCityDetails } from "../../../helpers/CommonHelper";
import { DATETIME_FORMAT_STRINGS, PLATFORM_USERS } from "../../../utils/constants/constants";
import moment from "moment";

interface IBuyers extends RouteComponent {
  page?: string;
}
const Buyers = (props: IBuyers) => {
  const { routeKey } = props;
  const isDashboard = props.page === "dashboard";
  const dispatch = useDispatch<AppDispatch>();
  const { user, c_uuid } = useCompanySelector(routeKey);

  const [query, setQuery] = useState<any>({
    c_uuid,
    page: 1,
    limit: isDashboard ? 5 : 10,
    sort: "",
    sortBy: "",
    filter: "",
  });

  const onPageQueryChange = (page?: number, limit?: number) => {
    setQuery({ ...query, page, limit });
  };
  const onSortQueryChange = (sort?: string, sortBy?: string) => {
    setQuery({ ...query, sort, sortBy });
  };
  // added { refetchOnMountOrArgChange: true } for rendering on mount
  const { data, isLoading, refetch } = useGetBuyersQuery(
    { ...query, user },
    { refetchOnMountOrArgChange: true }
  );

  const columns = [
    {
      id: "company_name",
      numeric: false,
      disablePadding: false,
      label: "Company Details",
      callBackArguments: ["id", "isItemSelected", "labelId"],
      getCustomColumn: true,
      getColData: (row: any) => {
        return (<>
        <Link
            to={`${row.id}/details`}
            className="underline underline-offset-4 font-semibold text-md"
          >{row.name}</Link>
        <p className="text-xs pt-2">URL: { generateLoginUrl(PLATFORM_USERS.BUYER, `${row?.slug || ""}/login`)}</p>
        </>)
      },
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Contact Details",
      callBackArguments: ["id", "isItemSelected", "labelId"],
      maxWidth: '400px',
      getCustomColumn: true,
      getColData: (row: any) => {
        return (<>
        Name: {row?._owner?.name}
        <br/>
        Email: {row?._owner?.email}
        <br/>
        Contact No.: {row?._owner?.contact_number}
        </>)
      },
    },
    {
      id: "Location",
      numeric: false,
      disablePadding: false,
      label: "Location",
      getCustomColumn: true,
      getColData: (row: any) => {
        return (<>
       {row?.company?._city ? getCityDetails(row?.company)?.getInfo: "NA"}
        </>)
      },
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      label: "Added On",
      getCustomColumn: true,
      getColData: (row: any) => {
        return moment(row.created_at).format(DATETIME_FORMAT_STRINGS.STANDARD_DATE_STRING);
      },
    },
  ];

  const actions = [
    {
      id: "approve",
      label: "Approve",
      callbackArguments: [],
      customLabel: (row: any) => (row?.is_approved ? "Disapprove" : "Approve"),
      onClick: (row: any) => {
        dispatch(
          approveBuyer({
            user: routeKey,
            c_uuid,
            buyerId: row?.id,
          })
        ).then(() => {
          /** hack -  refetch on success of approve /disapprove */
          refetch();
        });
      },
    },
    // {
    //   id: "delete",
    //   label: "Archive",
    //   callbackArguments: ["id"],
    //   onClick: (id: any) => {

    //   },
    // },
  ];
  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <div className="flex justify-between my-8">
        {isDashboard ? (
          <SectionTitle>New Buyers</SectionTitle>
        ) : (
          <PageTitle className="">All Buyers</PageTitle>
        )}
      </div>
      <EnhancedTable
        columns={columns}
        rows={data?.data || []}
        {...(isDashboard ? {} : { meta: data?.meta })}
        onPageChange={onPageQueryChange}
        onSortChange={onSortQueryChange}
        actions={actions}
        image={require("../../../assets/img/rfq/datagrid.svg").default}
        headingText={"No Buyer"}
        description={"You don't have any Buyer yet"}
      />
    </>
  );
};

export default Buyers;
