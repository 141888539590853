import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import userReducer from "../features/user/userSlice";
import companyReducer from "../features/company/companySlice";
import supplierReducer from "../features/supplier/supplierSlice";
import adminSlice from "../features/admin/adminSlice";
import rfqSlice from "../features/rfq/rfqSlice";
import supplierRfqSlice from "../features/rfq/supplierRfqSlice";
import { api } from "../features/paginationApis";

const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    company: companyReducer,
    supplier: supplierReducer,
    buyers: adminSlice,
    rfq: rfqSlice,
    supplierRfq: supplierRfqSlice,
    api: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
