import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { API_URLS, GLOBAL_API_URL } from "../../../utils/constants/apiUrls";
import { AnyAction, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/interceptor";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";

export const getCompanies = createAsyncThunk(
  "company/info",
  async (companyName: string, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        `${GLOBAL_API_URL.company}/${companyName}`
      );
      return result;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const getComapnyDetails = createAsyncThunk(
  "compnay/details",
  async (data: { user: string; c_uuid: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.get(
        `${API_URLS[data.user]?.comapnyDetails}?c_uuid=${data.c_uuid}`
      );
      return result;
    }
  }
);

export const getAllDeliveryAddresses = async (data: {
  user: string;
  c_uuid: string;
  query?: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.getAllDeliveryAddresses}`,
      {
        params: {
          c_uuid: data.c_uuid,
          ...data?.query,
        },
      }
    );

    return result;
  }
};

export const updateComapnyDetails = createAsyncThunk(
  "updateCompnay/details",
  async (data: { user: string; c_uuid: string; data: any }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.put(
        `${API_URLS[data.user]?.comapnyDetails}?c_uuid=${data.c_uuid}`,
        data.data
      );
      return result;
    }
  }
);

export const addComapnyUser = async (data: { user: string; data: any }) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.post(
      `${API_URLS[data.user]?.addCompanyUser}`,
      data.data
    );
    return result;
  }
};

export const getCompanyUserRoles = createAsyncThunk(
  "getCompanyUserRoles",
  async (data: { user: string; c_uuid: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.get(
        `${API_URLS[data.user]?.getCompanyUserRoles}?c_uuid=${data.c_uuid}`
      );
      return result;
    }
  }
);

export const getCompanyUsers = createAsyncThunk(
  "company-users",
  async (data: { user: string; c_uuid: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.get(
        `${API_URLS[data.user]?.getCompanyUsers}?c_uuid=${data.c_uuid}`
      );
      return result;
    }
  }
);

//export const buyerUserApi = createApi({
// baseQuery: axiosBaseQuery({
//   baseUrl: `${API_URLS.buyer.getCompanyUsers}`,
// }),
// endpoints: (builder) => ({
//   getUsers: builder.query<
//     any,
//     {
//       c_uuid: string;
//       page?: number;
//       limit?: number;
//       sort?: string;
//       sortBy?: string;
//       filter?: string;
//     }
//   >({
//     query: (arg): any => {
//       const { c_uuid, page, limit, sort, sortBy, filter } = arg;
//       return {
//           method: "get",
//           url: "",
//           params: { c_uuid, page, limit, sort, sortBy, filter },
//         };
//       },
//     }),
//   }),
// });

export const getCompanyInvitedUsers = createAsyncThunk(
  "company-invited-users",
  async (data: { user: string; c_uuid: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.get(
        `${API_URLS[data.user]?.getCompanyInvitedUsers}?c_uuid=${data.c_uuid}`
      );
      return result;
    }
  }
);

export const resendCompanyUserInvite = async (data: {
  user: string;
  c_uuid: string;
  data: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.post(
      `${API_URLS[data.user].postCompanyUserResendInvite}`,
      data.data
    );
    return result;
  }
};

export const getAllBillingEntities = createAsyncThunk(
  "all-billing-entities",
  async (data: { user: string; c_uuid: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.get(
        `${API_URLS[data.user].getAllBillingEntity}?c_uuid=${data.c_uuid}`
      );
      return result;
    }
  }
);

export const getBillingEntity = async (data: {
  user: string;
  c_uuid: string;
  billingEntityId: any;
  query?: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user].getBillingEntity}/${data.billingEntityId}`,
      {
        params: { c_uuid: data.c_uuid, ...data.query },
      }
    );
    return result;
  }
};

export const createBillingEntity = async (data: {
  user: string;
  c_uuid: string;
  data: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.post(
      `${API_URLS[data.user].addBllingEntity}`,
      data.data
    );
    return result;
  }
};

export const updateBillingEntity = async (data: {
  user: string;
  c_uuid: string;
  billingEntityId: any;
  data: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.put(
      `${API_URLS[data.user].updateBillingEntity}/${data.billingEntityId}`,
      data.data
    );
    return result;
  }
};

export const addBillingDelivery = async (data: {
  user: string;
  c_uuid: string;
  data: any;
}) => {
  const result = await axiosInstance.post(
    `${API_URLS[data.user].addBillingDelivery}`,
    data.data
  );
};

export const updateBillingDelivery = async (data: {
  user: string;
  c_uuid: string;
  delivery_address_uuid: string;
  data: any;
}) => {
  const result = await axiosInstance.put(
    `${API_URLS[data.user].updateBillingDelivery}/${
      data.delivery_address_uuid
    }`,
    data.data
  );
  return result;
};
export const deleteBillingDelivery = async (data: {
  user: string;
  c_uuid: string;
  delivery_address_uuid: string;
}) => {
  const result = await axiosInstance.delete(
    `${API_URLS[data.user].updateBillingDelivery}/${data.delivery_address_uuid}`
  );
};
export const getAllRfqTemplates: any = async (data: {
  user: string;
  c_uuid: string;
}) => {
  const result = await axiosInstance.get(
    `${API_URLS[data.user].getAllRFQTemplates}${data.c_uuid}`
  );
  return result;
};

export const addRfqTemplate: any = async (data: {
  user: string;
  c_uuid: string;
  // delivery_address_uuid: string;
  data: any;
}) => {
  const result = await axiosInstance.post(
    `${API_URLS[data.user].addRFQTemplate}`,
    data.data
  );
};

export const getRfqTemplate: any = async (data: {
  user: string;
  c_uuid: string;
  rfq_template_uuid: string;
  data: any;
}) => {
  const result = await axiosInstance.get(
    `${API_URLS[data.user].getRFQTemplate}/${data.rfq_template_uuid}?c_uuid=${
      data.c_uuid
    }`,
    data.data
  );
  return result;
};

export const editRfqTemplate: any = async (data: {
  user: string;
  c_uuid: string;
  rfq_template_uuid: string;
  data: any;
}) => {
  const result = await axiosInstance.put(
    `${API_URLS[data.user].getRFQTemplate}/${data.rfq_template_uuid}`,
    data.data
  );
  return result;
};

export const getComapnyMandatoryCertificate = async (data: {
  c_uuid: string;
}) => {
  const result = await axiosInstance.get(
    GLOBAL_API_URL.getMandatoryCertificate,
    {
      params: { c_uuid: data.c_uuid },
    }
  );
  return result;
};

export const postComapnyMandatoryCertificate = async (data: {
  c_uuid: string;
  data: any;
}) => {
  const result = await axiosInstance.post(
    GLOBAL_API_URL.postMandatoryCertificate,
    {
      c_uuid: data.c_uuid,
      ...data.data,
    }
  );
  return result;
};

export const putComapnyMandatoryCertificate = async (data: {
  c_uuid: string;
  data: any;
}) => {
  const result = await axiosInstance.put(
    GLOBAL_API_URL.putMandatoryCertificate(data.data.id),
    {
      c_uuid: data.c_uuid,
      ...data.data,
    }
  );
  return result;
};

export const uploadUsers = async (data: {
  user: string;
  c_uuid: string;
  data: any;
}) => {
  const token = localStorage.getItem("token");
  if (token) {
    const result = await axios.post(
      `${API_URLS[data.user].bulkUploadUsers(data.c_uuid)}`,
      data.data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          c_uuid: data.c_uuid,
        },
      }
    );
    return result;
  }
};
export const getAllSystemPermissions = createAsyncThunk(
  "getAllSystemPermissions ",
  async (data: { user: string; c_uuid: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.get(
        `${API_URLS[data.user]?.getAllSystemPermissions}`,
        { params: { c_uuid: data.c_uuid } }
      );
      return result;
    }
  }
);

export const addRolePermission = createAsyncThunk(
  "addRolePermission",
  async (data: { user: string; c_uuid: string; data: any }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.post(
        `${API_URLS[data.user]?.addRole}`,
        { c_uuid: data.c_uuid, ...data.data }
      );
      return result;
    }
  }
);

export const editRolePermission = createAsyncThunk(
  "editRolePermission",
  async (data: { user: string; c_uuid: string; data: any; roleId: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.put(
        `${API_URLS[data.user]?.editRole(data.roleId)}`,
        { c_uuid: data.c_uuid, ...data.data }
      );
      return result;
    }
  }
);
