import React, { useEffect, useState } from "react";
import SectionTitle from "../../../common/Typography/SectionTitle";
import Table from "../../../common/Table";
import { useCompanySelector } from "../../../hooks/useCompanySelector";
import { getCompanyUserRoles } from "../../../redux/features/company/companyApi";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { API_CONSTANTS } from "../../../utils/constants/constants";
import CommonButton from "../../../common/Button";
import { RouteComponent } from "../../../utils/common";
import ManageRolesPermession from "../ManageRolePermission";
import FullPageLoader from "../../../common/FullPageLoader";
import toast from "react-hot-toast";

const columns = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    getCustomColumn: true,
    getColData: (row: any) => row?.display_name || row?.name,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "description",
    getCustomColumn: true,
    getColData: (row: any) => row?.description || "NA",
  },
  {
    id: "noOfUsers",
    numeric: false,
    disablePadding: false,
    label: "No of users assigned",
    getCustomColumn: true,
    getColData: (row: any) => (
        <>{row?.users?.length} users</>
    ),
  },
];

const RolePermissions = (props: RouteComponent) => {
  const { user, c_uuid } = useCompanySelector(props.routeKey);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const companySelector = useSelector((state: any) => state.company);
  const dispatch = useDispatch<AppDispatch>();
  const [addPermission, setAddPermission] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const handleAddPermission = () => {
    setAddPermission(!addPermission);
    setSelectedRole({});
    setType("add");
  };

  const actions = [
    {
      id: "edit",
      label: "Edit",
      callbackArguments: [],
      //disabled: (row: any) => row.status !== RFQ_STATUS_CONSTANTS.draft,
      onClick: (row: any) => {
        setAddPermission(true);
        setType("edit");
        setSelectedRole(row);
      },
    },
    {
      id: "delete",
      label: "Delete",
      callbackArguments: ["id"],
      disabled: (row: any) => row?.category === "system",
      onClick: (id: any) => {
        //navigate(APP_USER_URLS.rfqViewDetailsBuyer(id));
      },
    },
  ];

  useEffect(() => {
    dispatch(getCompanyUserRoles({ user, c_uuid }));
  }, []);

  useEffect(() => {
    if (companySelector.companyUserRoles?.status === API_CONSTANTS.loading) {
      
      setLoading(true)
    }
    if (companySelector.companyUserRoles?.status === API_CONSTANTS.success) {
      const data = companySelector.companyUserRoles.data;
      setLoading(false)
      setRoles(data);
    }
    if (companySelector.companyUserRoles?.status === API_CONSTANTS.error) {
      setLoading(false)
    }
  }, [companySelector]);

  if(loading){
    <FullPageLoader />
  }
  
  return (
    <div className="my-6">
      {addPermission ? (
        <ManageRolesPermession
        {...props}
          handleAddPermission={handleAddPermission}
          type={type}
          role={selectedRole}
        />
      ) : (
        <>
          <div className="flex justify-between mb-6">
            <SectionTitle className="">Roles and Permissions</SectionTitle>
            <CommonButton text="Add New Role" onClick={handleAddPermission} />
          </div>

          <Table
            rows={roles}
            columns={columns}
            actions={actions}
            image={require("../../../assets/img/rfq/datagrid.svg").default}
            headingText={"No Roles"}
            description={"You don't have any Role yet"}
            text={"Create Role"}
            onClickFunc={() => {}}
          />
        </>
      )}
    </div>
  );
};

export default RolePermissions;
