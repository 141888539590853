import moment from "moment";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../helpers/CommonHelper";
import { APP_USER_URLS } from "../../utils/constants/appUrls";
import CommonLink from "../CommonLink";

const Label = (props: any) => {
  return (
    <div className="text-sm font-semibold text-brand-extra-icon">
      {props.children}
    </div>
  );
};

const Value = (props: any) => {
  return (
    <div className="text-base text-brand-text-title font-normal">
      {props.children}
    </div>
  );
};

const QuotationDetailsCardComponent = (props: {
  quotationDetails: any;
  rfqDetails: any;
  user: string;
  rfqRequest?: any;
}) => {
  const navigate = useNavigate();
  const { quotationDetails, rfqDetails, user, rfqRequest } = props;

  return (
    <div className="">
      <div className="flex justify-between items-center pt-8 mb-4">
        <div className="text-base font-semibold text-brand-text-title">
          Quotation Details
        </div>
        {rfqRequest? (
        <>
          <div>
              <CommonLink
                className="px-2"
                text={"Quotations History"}
                onClick={() =>
                  navigate(
                    `${APP_USER_URLS.supplierQuotationHistory(
                      rfqRequest.id
                    )}`
                  )
                }
              />
          </div>
        </>): ''}
      </div>
      <div className="border bg-white">
        <div className="grid grid-cols-4 gap-x-4 gap-y-8 p-6">
          <div>
            <Label>Quotation No.</Label>
            <Value>{quotationDetails.quotation_no}</Value>
          </div>
          <div>
            <Label>RFQ No.</Label>
            <Value>{rfqDetails?.rfq_no}</Value>
          </div>
          <div>
            <Label>Submitted On</Label>
            <Value>
              {" "}
              {quotationDetails.submitted_at
                ? moment(quotationDetails.submitted_at).format("MMM DD, YYYY hh:mmA")
                : ""}
            </Value>
          </div>
          <div>
            <Label>Total Price</Label>
            <Value>Rs. {formatPrice(quotationDetails?.total_price)}</Value>
          </div>

        </div>
        <div className="gap-x-4 p-6 pt-2">
          <div>
            <Label>Remarks</Label>
            <Value>{quotationDetails.remarks || "NA"}</Value>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotationDetailsCardComponent;
