import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function NavTabs(props: { tabList: any[]; info?: any, currentTab: any, onChange?: any }) {
  const { tabList, info, currentTab, onChange } = props;
  return (
    <Tabs value={currentTab} onChange={onChange}>
      {tabList.map((tab) => {
        return (
          <Tab
            key={tab.label}
            label={tab.label}
            value={tab.href}
            {...info}
            sx={{
              paddingBottom: '1rem',
              textTransform: 'capitalize',
              "&.Mui-selected":{
                fontWeight: '600',
                color:'#286EF1',
                borderBottomColor: "#286EF1"
              }
              
            }
            }
          />
        );
      })}
    </Tabs>
  );
}
