import { API_URLS, GLOBAL_API_URL } from "../../../utils/constants/apiUrls";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/interceptor";
import { PLATFORM_USERS } from "../../../utils/constants/constants";

export const getBuyers = createAsyncThunk(
  "getBuyers",
  async (data: { user: string; c_uuid: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.get(
        `${API_URLS[data.user]?.getBuyers}?c_uuid=${data.c_uuid} `
      );
      return result;
    }
  }
);

export const approveBuyer = createAsyncThunk(
  "approveBuyer",
  async (data: { user: string; c_uuid: string; buyerId: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.patch(
        `${API_URLS[data.user]?.approveBuyer(data.buyerId)}`,
        {
          c_uuid: data.c_uuid,
        }
      );
      return result;
    }
  }
);

export const getUsers = createAsyncThunk(
  "getUsers",
  async (data: { user: string; buyerId: string; c_uuid: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.get(
        `${API_URLS[data.user]?.getUsers(data.buyerId, data.c_uuid)}`
      );
      return result;
    }
  }
);

export const getSuppliers = createAsyncThunk(
  "getSuppliers",
  async (data: { user: string; buyerId: string; c_uuid: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.get(
        `${API_URLS[data.user]?.getSuppliers(data.buyerId, data.c_uuid)}`
      );
      return result;
    }
  }
);

export const getByuerSuppliers = async (data: {
  user: string;
  c_uuid: string;
  buyerId: string;
  query: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.getBuyerSuppliers(data.buyerId)}`,
      {
        params: {
          ...data.query,
        },
      }
    );
    return result;
  }
};

export const getBuyerUsers = async (data: {
  user: string;
  c_uuid: string;
  buyerId: string;
  query: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.getUsers(data.buyerId)}`,
      { params: { ...data.query } }
    );
    return result;
  }
};

export const getBuyersCompanyDetail = async (data: {
  user: string;
  c_uuid: string;
  buyerId: string;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.getBuyerCompanyDetails(data.buyerId)}`,
      { params: { c_uuid: data.c_uuid } }
    );
    return result;
  }
};

export const getBuyerRfqs = async (data: {
  user: string;
  c_uuid: string;
  buyerId: string;
  query: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.getBuyerRfqs(data.buyerId)}`,
      { params: { ...data.query } }
    );
    return result;
  }
};

export const getRfqByRfqId = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;

}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.getRfq(data.rfqId)}`,
      { params: { c_uuid: data.c_uuid } }
    );
    return result;
  }
};

export const getRfqRequests = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
query: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.getRfqRequests(data.rfqId)}`,
      { params: { c_uuid: data.c_uuid, ...data.query } }
    );
    return result;
  }
};

export const getSupplierCompanyDetail = async (data: {
  user: string;
  c_uuid: string;
  supplierId: string;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.getSupplierCompanyDetails(data.supplierId)}`,
      { params: { c_uuid: data.c_uuid } }
    );
    return result;
  }
};

export const getSupplierUsers = async (data: {
  user: string;
  c_uuid: string;
  buyerId: string;
  query: any;
}) => {
  const result = await axiosInstance.get(
    `${API_URLS[data.user]?.getSupplierUsers(data.buyerId)}`,
    { params: { ...data.query } }
  );
  return result;
};

export const updateBuyerComapnyDetails = async (data: {
  user: string;
  c_uuid: string;
  buyerId: string;
  data: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.put(
      `${API_URLS[data.user]?.updateCompany(data.buyerId)}?c_uuid=${data.c_uuid}`,
      data.data
    );
    return result;
  }
};

export const updateSupplierComapnyDetails = async (data: {
  user: string;
  c_uuid: string;
  supplierId: string;
  data: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.put(
      `${API_URLS[data.user]?.updateCompany(data.supplierId)}?c_uuid=${data.c_uuid}`,
      data.data
    );
    return result;
  }
};
