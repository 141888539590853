import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import FullPageLoader from "../../../common/FullPageLoader";
import PageTitle from "../../../common/Typography/PageTitle";
import Table from "../../../common/Table";
import {
  getRecommendationListApprovals,
  postApproveRecommendation,
} from "../../../redux/features/recommendations/recommendationApi";
import { STATUS_JSX } from "../../../utils/constants/status";
import toast from "react-hot-toast";
import moment from "moment";
import { APP_USER_URLS } from "../../../utils/constants/appUrls";
import { Link, useNavigate } from "react-router-dom";
import { DATETIME_FORMAT_STRINGS, LOADING_TEXT } from "../../../utils/constants/constants";
import { downloadRecommendationQuotation } from "../../../redux/features/rfq/rfqApi";
import CommonLink from "../../../common/CommonLink";
import CommonModal from "../../../common/Modal";
import { RouteComponent } from "../../../utils/common";

const PendingApproval: React.FC<RouteComponent> = (props: RouteComponent) => {
  const user: string = props.routeKey;
  const companySelector = useSelector((state: any) => state.company);
  const companyLocalStorage = localStorage.getItem("company");
  const parsedLocalStorage =
    companyLocalStorage && JSON.parse(companyLocalStorage);
  const c_uuid: string = companySelector.company.id || parsedLocalStorage.id;
  const [reccomandations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [meta, setMeta] = useState({});
  const [query, setQuery] = useState<any>({
    c_uuid,
    page: 1,
    limit: 10,
    sort: "",
    sortBy: "",
    filter: "",
  });

  const [openQuotationsModal, setOpenQuotationsModal] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    getRecommendationListApprovals({ user, c_uuid, query }).then((response: any) => {
      setRecommendations(response.data);
      setMeta(response.meta)
      setLoading(false);
    });
  }, []);
  if (loading) {
    return <FullPageLoader />;
  }

  const handleRecommendationQuotationDownload = (user:any, c_uuid:string, rfqId:string, recommendationId: string, quotationId:string) => {
    let toastId = toast.loading(LOADING_TEXT.processing);
    rfqId && recommendationId &&
      quotationId &&
        downloadRecommendationQuotation({
          user,
          c_uuid,
          rfqId,
          recommendationId,
          quotationId,
        })
        .then(() => {
          toast.dismiss(toastId);
          toast.success("Recommendation Downloaded successfully")
        })
        .catch((e: any) => {
          toast.dismiss(toastId);
          toast.error("Some error occured")
        });
  };

  const markApprove = (rowId: any) => {
    let toastId = toast.loading(LOADING_TEXT.processing);
    postApproveRecommendation({
      user,
      c_uuid,
      recommendationId: rowId,
      data: {},
    }).then(() => {
      toast.dismiss(toastId);
      toast.success("Recommendation is successfully approved");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
       
    }).catch(() => {
      toast.dismiss(toastId);
      toast.error("Some error occurred");
    });
  };
  const onPageQueryChange = (page?: number, limit?: number) => {
    setQuery({ ...query, page, limit });
  };
  const onSortQueryChange = ( sort?: string, sortBy?: string) => {
    setQuery({ ...query, sort, sortBy });
  };

  const openRecommendationQuotationsModal = (row: any) => {
    setOpenQuotationsModal(true);
    setSelectedRecommendation(row);
  };

  const quotationTableColumns = [
    {
      id: "quotation_no",
      numeric: false,
      disablePadding: false,
      label: "Quotation No.",
    },
    {
      id: "view",
      label: "Products Count",
      callbackArguments: ["id"],
      disablePadding: false,
      getCustomColumn: true,
      getColData: (row: any) => row?.quotation_products?.filter((item:any) => item.is_recommended).length || 'NA',
    },
    {
      id: "view",
      label: "Download PDF",
      callbackArguments: ["id"],
      disablePadding: false,
      getCustomColumn: true,
      getColData: (row: any) => (
        <CommonLink
          text={"Download"}
          onClick={() => handleRecommendationQuotationDownload(user, c_uuid, selectedRecommendation.rfq.id, selectedRecommendation.id, row.id)}
        />
      ),
    },
  ];

  const tableColumns = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "RFQ NO.",
      callBackArguments: ["id"],
      getCustomColumn: true,
      getColData: (row: any) => (
        <Link
          to={`${APP_USER_URLS.rfqViewDetailsBuyer(row.recommendation.rfq.id)}`}
          className="underline underline-offset-4 font-semibold"
        >
          {row.recommendation.rfq.rfq_no}
        </Link>
      ),
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      getCustomColumn: true,
      getColData: (row: any) => STATUS_JSX[row.recommendation.status],
      label: "Status",
    },
    {
      id: "quotations_count",
      label: "Quotations",
      numeric: false,
      disablePadding: false,
      getCustomColumn: true,
      getColData: (row: any) => row?.recommendation?.quotations?.length || 0,
    },
    {
      id: "download",
      numeric: false,
      disablePadding: false,
      label: "Download PDF",
      getCustomColumn: true,
      getColData: (row: any) => (
        <CommonLink
          text={"Download"}
          onClick={() => openRecommendationQuotationsModal(row.recommendation) }
        />
      ),
    },
    {
      id: "created_at",
      label: "Created On",
      numeric: false,
      disablePadding: false,
      getCustomColumn: true,
      getColData: (row: any) => row?.recommendation?.created_at ? moment(row?.recommendation?.created_at).format(DATETIME_FORMAT_STRINGS.STANDARD_DATE_STRING) : "NA",
    },
    {
      id: "submission_at",
      label: "RFQ Last Submission",
      numeric: false,
      disablePadding: false,
      getCustomColumn: true,
      getColData: (row: any) => row?.recommendation?.rfq?.submission_at ? moment(row?.recommendation?.rfq?.submission_at).format(DATETIME_FORMAT_STRINGS.STANDARD_DATE_STRING) : "NA",
    },
  ];

  const approvalActions =  [
    {
      id: "view",
      label: "Approve",
      callbackArguments: ["id"],
      getCustomColumn: true,
      onClick: (row:any) => {
        markApprove(row)
      },
    },
    {
      id: "view",
      label: "Compare quotations",
      callbackArguments: [],
      onClick: (row:any) => {
        navigate(APP_USER_URLS.buyerComapreRfq(row.recommendation.rfq.id));
      },
    },
  ];

  return (
    <>
      <PageTitle className="my-6">Pending Approvals</PageTitle>
      <Table
        columns={tableColumns}
        rows={reccomandations}
        actions={approvalActions}
        image={require("../../../assets/img/rfq/datagrid.svg").default}
        headingText={"No Pending Approvals"}
        description={"You don't have any pending approvals yet"}
        meta={meta}
        onPageChange={onPageQueryChange}
        onSortChange={onSortQueryChange}
      />

      <CommonModal
        open={openQuotationsModal}
        setOpen={setOpenQuotationsModal}
        content={
          <div className="w-full mt-4">
            <Table columns={quotationTableColumns} rows={selectedRecommendation?.quotations} actions={[]} />
          </div>
        }
        headingText={"Download Recommendations"}
        maxWidth={"md"}
      />
    </>
  );
};

export default PendingApproval;
