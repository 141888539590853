import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackIconImage from "../../../assets/img/home/arrow-left.svg";
import CommonButton from "../../../common/Button";
import Input from "../../../common/Input/MaterialInput";
import SectionTitle from "../../../common/Typography/SectionTitle";
import { useCompanySelector } from "../../../hooks/useCompanySelector";
import {
  addRolePermission,
  editRolePermission,
  getAllSystemPermissions,
} from "../../../redux/features/company/companyApi";
import { AppDispatch } from "../../../redux/store";
import {
  API_CONSTANTS,
  LOADING_TEXT,
} from "../../../utils/constants/constants";
import { Checkbox, FormControlLabel } from "@mui/material";
import { FormikValues, useFormik } from "formik";
import toast from "react-hot-toast";
import FullPageLoader from "../../../common/FullPageLoader";

interface ManageRolePermissionProps {
  handleAddPermission: () => void;
  type: string;
  role: any;
  routeKey: string;
}

const DepartmentRole = ({ permissionList, val, formik }: any) => {
  return (
    <div className="border rounded-sm my-4 p-4">
      {permissionList && val ? (
        <React.Fragment key={val}>
          <div className="text-md font-semibold capitalize mb-6">
            {val} Permissions
          </div>
          <div className="grid grid-cols-3 gap-2">
            {permissionList[val]?.map((value: any) => {
              return (
                <FormControlLabel
                  key={value.id}
                  control={
                    <Checkbox
                      name={value.display_name}
                      key={value.display_name}
                      color="primary"
                      value={value.id}
                      //indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={formik.values?.permissions?.includes(value.id)}
                      onChange={(e) => {
                        e.preventDefault();
                        if (formik.values?.permissions?.includes(value.id)) {
                          const index = formik.values?.permissions.indexOf(
                            value.id
                          );
                          if (index > -1) {
                            // only splice array when item is found
                            const newArr = [...formik.values?.permissions];
                            newArr?.splice(index, 1);
                            console.log(newArr);
                            formik.setFieldValue("permissions", newArr); // 2nd parameter means remove one item only
                          }
                        } else {
                          formik.setFieldValue("permissions", [
                            ...formik.values.permissions,
                            value.id,
                          ]);
                        }
                      }}
                      inputProps={{
                        "aria-label": "",
                      }}
                      size="small"
                      sx={{
                        color: "#9DACB4",
                      }}
                    />
                  }
                  label={
                    <span style={{ fontSize: "0.875rem" }}>
                      {value.display_name}
                    </span>
                  }
                  sx={{
                    fontSize: "0.875rem",
                  }}
                />
              );
            })}
          </div>
        </React.Fragment>
      ) : (
        <></>
      )}
    </div>
  );
};
const ManageRolesPermession = (props: ManageRolePermissionProps) => {
  const { user, c_uuid } = useCompanySelector(props.routeKey);
  const [loading, setLoading] = useState(false);
  const [permissionList, setPermissionList] = useState<any>({});
  const dispatch = useDispatch<AppDispatch>();
  const companySelector = useSelector((state: any) => state.company);
  const formik = useFormik({
    initialValues: {
      name: "",
      permissions: [],
      description: "",
    },
    onSubmit: (values: FormikValues) => {
      let toastId = toast.loading(LOADING_TEXT.saving);
      if (props.type === "add") {
        dispatch(addRolePermission({ user, c_uuid, data: values })).then(
          (result) => {
            toast.dismiss();
            toast.success("Saved");
          }
        );
      }
      if (props.type === "edit") {
        dispatch(
          editRolePermission({
            user,
            c_uuid,
            data: values,
            roleId: props.role.id,
          })
        ).then(() => {
          toast.dismiss();
          toast.success("Saved");
        });
      }
    },
  });
  useEffect(() => {
    if (companySelector.companyPermission?.status === API_CONSTANTS.loading) {
      setLoading(true);
    }
    if (companySelector.companyPermission?.status === API_CONSTANTS.success) {
      const data = companySelector.companyPermission.data;
      let result = data.reduce(function (r: any, a: any) {
        r[a.type] = r[a.type] || [];
        r[a.type].push(a);
        return r;
      }, Object.create(null));
      setPermissionList(result);
      setLoading(false);
    }
    if (companySelector.companyPermission?.status === API_CONSTANTS.error) {
      setLoading(false);
    }
  }, [companySelector]);
  useEffect(() => {
    dispatch(getAllSystemPermissions({ user, c_uuid }));
  }, [props.type]);
  const permissionGroup = Object.keys(permissionList);

  useEffect(() => {
    if (props.type === "edit") {
      const newdata = {
        name: props.role?.display_name || props.role?.name,
        description: props.role.description,
        permissions: props.role.permissions.map(
          (permission: any) => permission.id
        ),
      };
      formik.setValues({ ...newdata });
    }
  }, [props.type]);

  if (loading) {
    <FullPageLoader />;
  }

  return (
    <>
      <div className="flex space-x-2 mb-6">
        <img
          alt={"Go back"}
          className={`cursor-pointer`}
          src={BackIconImage}
          onClick={() => {
            props.handleAddPermission();
          }}
        />
        <SectionTitle>
          {" "}
          {props.type === "add" ? "Add Role" : "Edit Role"}
        </SectionTitle>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-2">
          <div>
            <Input
              name="name"
              label="Role Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              disabled={props?.role?.category === "system"}
            ></Input>
            <Input
              name="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              disabled={props?.role?.category === "system"}
            ></Input>
            <CommonButton
              className="mt-6 mb-6"
              text="Save"
              disabled={props?.role?.category === "system"}
              type="submit"
            />
            {props?.role?.category === "system" ? (
              <>
                <p className="-mt-4 text-xs font-normal flex-inline">
                  Note: System roles cannot be edited
                </p>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <SectionTitle className="mt-4">Permissions</SectionTitle>
        {permissionGroup?.map((group: string) => (
          <DepartmentRole
            permissionList={permissionList}
            val={group}
            formik={formik}
          />
        ))}
        <CommonButton
          className="mt-6 mb-6"
          text="Save permissions"
          disabled={props?.role?.category === "system"}
          type="submit"
        />
      </form>
    </>
  );
};

export default ManageRolesPermession;
