import { createSlice } from "@reduxjs/toolkit";
import { getSupplier } from "./supplierApi";
const initialState: any = {
    allSupplier: {
        status: 'idle',
        data: null
    },
    addSupplier: {
        status: 'idle',
        data: null
    }
}
const supplierSlice = createSlice({
    initialState,
    name: "supplierSlice",
reducers: {},
extraReducers: (builder) => {
    builder.addCase(getSupplier.pending, (state, action) => {
        state.allSupplier.status = "pending";
      });
      builder.addCase(getSupplier.fulfilled, (state, action) => {
        state.allSupplier.status = "succeeded";
        state.allSupplier.data = action.payload;
      });
      builder.addCase(getSupplier.rejected, (state, action) => {
        state.allSupplier.status = "failed";
        state.allSupplier.data = action.error.message;
      });
}
})

export default supplierSlice.reducer;