import { API_URLS, GLOBAL_API_URL } from "../../../utils/constants/apiUrls";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/interceptor";
import axios from "axios";

export const getAllSupplierRfq = createAsyncThunk(
  "getAllSupplierRfq",
  async (data: { user: string; c_uuid: string }) => {
    if (API_URLS[data.user]) {
      const result = await axiosInstance.get(
        `${API_URLS[data.user]?.getAllRfq}`,
        {
          params: {
            c_uuid: data.c_uuid,
          },
        }
      );
      return result;
    }
  }
);

export const getSupplierRfq = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.getRfq}/${data.rfqId}`,
      {
        params: {
          c_uuid: data.c_uuid,
        },
      }
    );
    return result;
  }
};

export const getSupplierRfqByRequest = async (data: {
  user: string;
  c_uuid: string;
  requestId: string;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.getRfqByRequestId}/${data.requestId}`,
      { params: { c_uuid: data.c_uuid } }
    );
    return result;
  }
};

export const acceptParticipation = async (data: {
  user: string;
  c_uuid: string;
  requestId: string;
  acceptStatus: boolean;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.patch(
      `${API_URLS[data.user]?.acceptParticipation}/${data.requestId}/accept`,
      { c_uuid: data.c_uuid, accept_status: data.acceptStatus }
    );
    return result;
  }
};

export const createQuotation = async (data: {
  user: string;
  c_uuid: string;
  requestId: string;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.post(
      `${API_URLS[data.user]?.createQuotation}/${data.requestId}/quotation`,
      { c_uuid: data.c_uuid }
    );
    return result;
  }
};

export const updateQuotation = async (data: {
  user: string;
  c_uuid: string;
  quotationId: string;
  data: any;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.put(
      `${API_URLS[data.user]?.updateQuotation}/${data.quotationId}/sync`,
      { c_uuid: data.c_uuid, ...data.data }
    );
    return result;
  }
};

export const uploadQuotation = async (data: {
  user: string;
  c_uuid: string;
  quotationId: string;
  data: any;
}) => {
  if (API_URLS[data.user]) {
    const token = localStorage.getItem("token");
    if(token) {
      const result = await axios.post(
        `${API_URLS[data.user]?.uploadQuotation(data.quotationId)}?c_uuid=${data.c_uuid}`,
        data.data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            c_uuid: data.c_uuid,
          },
        }
      );
      return result;
    }
    return null;
  }
};

export const getQuotationByQuotationId = async (data: {
  user: string;
  c_uuid: string;
  quotationId: string;
}) => {
  if (API_URLS[data.user] && API_URLS[data.user].viewQuotation) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.viewQuotation(data.quotationId)}`,
      { params: { c_uuid: data.c_uuid } }
    );
    return result;
  }
};

export const submitQuotataion = async (data: {
  user: string;
  c_uuid: string;
  quotationId: string;
  remarks: string;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.patch(
      `${API_URLS[data.user]?.getQuotationById}/${data.quotationId}/submit`,
      { c_uuid: data.c_uuid, remarks: data.remarks }
    );
    return result;
  }
};

export const getAllQuotations = async (data: {
  user: string;
  c_uuid: string;
  requestId: string;
}) => {
  if (API_URLS[data.user]) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user]?.allQuotations}/${data.requestId}/quotations`,
      { params: { c_uuid: data.c_uuid } }
    );
    return result;
  }
};
