import { createSlice, current } from "@reduxjs/toolkit";
import { getBuyers ,getUsers ,getSuppliers, approveBuyer } from "./adminApi";
const initialState: any = {
    buyers: {
        status: 'idle',
        data: []
    },
    users: {
      status: 'idle',
      data: null
  },
  suppliers: {
    status: 'idle',
    data: null
},
 
}
const buyersSlice = createSlice({
    initialState,
    name: "buyersSlice",
reducers: {},
extraReducers: (builder) => {

    builder.addCase(getBuyers.pending, (state, action) => {
        state.buyers.status = "pending";
      });
      builder.addCase(getBuyers.fulfilled, (state, action) => {
        state.buyers.status = "succeeded";
        state.buyers.data = action.payload?.data;
      });
      builder.addCase(getBuyers.rejected, (state, action) => {
        state.buyers.status = "failed";
        state.buyers.data = action.error.message;
      });

      builder.addCase(getUsers.pending, (state, action) => {
        state.users.status = "pending";
      });
      builder.addCase(getUsers.fulfilled, (state, action) => {
        state.users.status = "succeeded";
        state.users.data = action.payload;
      });
      builder.addCase(getUsers.rejected, (state, action) => {
        state.users.status = "failed";
        state.users.data = action.error.message;
      });

      builder.addCase(getSuppliers.pending, (state, action) => {
        state.suppliers.status = "pending";
      });
      builder.addCase(getSuppliers.fulfilled, (state, action) => {
        state.suppliers.status = "succeeded";
        state.suppliers.data = action.payload;
      });
      builder.addCase(getSuppliers.rejected, (state, action) => {
        state.suppliers.status = "failed";
        state.suppliers.data = action.error.message;
      });

      builder.addCase(approveBuyer.pending, (state, action) => {
        state.buyers.status = "pending";
      });
      builder.addCase(approveBuyer.fulfilled, (state, action: any) => {
        state.buyers.status = "succeeded";
        //state.buyers.data = action.payload;

      const index = current(state).buyers.data?.map((a: any) => a?.id)
        ?.indexOf(action.meta.arg.buyerId);
      if (index > -1) {
        const newArr = [...current(state).buyers.data]; 
        newArr[index] = {...newArr[index], is_approved: !newArr[index].is_approved}
        state.buyers.data = [...newArr];
      }
      });
      builder.addCase(approveBuyer.rejected, (state, action) => {
        state.buyers.status = "failed";
        //state.buyers.data = action.error.message;
      });
}
})

export default buyersSlice.reducer;