import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { getUserProfile } from "../redux/features/user/userApi";
import { APP_USER_ROUTES } from "../utils/constants/appRoutes";
import AuthHelpers from "../helpers/AuthHelpers";
import FullPageLoader from "../common/FullPageLoader";
import { PLATFORM_USERS } from "../utils/constants/constants";
import toast from "react-hot-toast";
import { AppDispatch } from "../redux/store";
import { IRoute } from "./interface";

/** Provide User Instance after calling me api */
export const PrivateUser = ({
  allowedRoles,
  routeKey,
}: {
  allowedRoles: string[];
  routeKey: string;
}) => {
  const isTokenAvailable = AuthHelpers.checkToken();
  const userType = AuthHelpers.getUserTypeFromLocalStorage() || routeKey;
  const dispatch = useDispatch<AppDispatch>();
  const userSelector = useSelector((state: any) => state.user);
  const compamySlug = AuthHelpers.getDataFromLocalStorage("company");
  const companyDetails = compamySlug && JSON.parse(compamySlug);

  const redirectUrl = companyDetails?.slug
    ? `/${userType}/${companyDetails.slug}/${APP_USER_ROUTES.login}`
    : `/${userType}/${APP_USER_ROUTES.login}`;

  const currentLocation = window.location.href.includes(
    `/${userType}/${APP_USER_ROUTES.company}/${APP_USER_ROUTES.companyDetails}`
  );
  useEffect(() => {
    if (isTokenAvailable && userType) {
      dispatch(getUserProfile({ user: userType }));
    }
  }, [isTokenAvailable, userType]);

  useEffect(() => {
    if (
      userSelector.data?.company &&
      !userSelector.data?.company?.onboarded &&
      (userType === PLATFORM_USERS.SUPPLIER)
    ) {
      toast.dismiss();
      toast("Please complete your profile", {
        icon: "⚠",
        style: {
          borderRadius: "10px",
          background: "#F5F8FF",
          color: "#3466E5",
          border: "1px solid #3466E5",
        },
        duration: 10000,
      });
    }
  }, [userSelector.data?.company]);

  const location = useLocation();
  useEffect(() => {
    if (!isTokenAvailable) {
      AuthHelpers.saveDataInLocalStorage("intendedUrl", location.pathname);
    }
  }, [location.pathname]);
  if (
    isTokenAvailable &&
    allowedRoles.includes(userSelector.data?.role as string)
  ) {
    localStorage.setItem("userType", userSelector.data?.role);
  }
  if (isTokenAvailable && !userSelector.data) {
    return <FullPageLoader />;
  }
  if (
    !userSelector.data?.company?.onboarded &&
    !currentLocation &&
    userType === PLATFORM_USERS.SUPPLIER
  ) {
    return (
      <Navigate
        to={`/${userType}/${APP_USER_ROUTES.company}/${APP_USER_ROUTES.companyDetails}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return isTokenAvailable ? (
    <Outlet />
  ) : (
    <Navigate to={redirectUrl} state={{ from: location }} replace />
  );
};

/** Non Authenticated routes */
export const PublicUser = ({ route }: { route: IRoute }) => {
  const checkToken = (): boolean => !!localStorage.getItem("token");
  const isTokenAvailable = checkToken();
  const location = useLocation();

  if (route.redirectSkip) {
    return <Outlet />;
  }
  return !isTokenAvailable ? (
    <Outlet />
  ) : (
    <Navigate
      to={`/${AuthHelpers.getUserTypeFromLocalStorage() || route.routeKey}/${
        APP_USER_ROUTES.dashboard
      }`}
      state={{ from: location }}
      replace
    />
  );
};
