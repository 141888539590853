import React from "react";

interface ButtonProps {
  text?: string;
  onClick?: any;
  type?: "button" | "submit" | "reset" | undefined;
  tag?: any;
  to?: string;
  className?: string;
  disabled?: boolean;
  theme?: string
}

const CommonButton: React.FC<ButtonProps> = (props) => {
  const { text, onClick, type, tag, to, className, disabled, theme } = props;
  return (
    <>
      <button
        type={type || "button"}
        className={`py-2.5 px-6 text-sm min-w-max border border-brand-primary-blue ${theme ? theme :"bg-brand-primary-blue hover:bg-brand-hover-blues text-white"} ${className ? className: ""} ${disabled ? "opacity-75": ""}`}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    </>
  );
};

export default CommonButton;
