/*eslint-disable*/
import React, { memo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { sideBarConfig } from "./sideBarConfig";
import AuthHelpers from "../../helpers/AuthHelpers";
import { ReactComponent as ChevronRightIcon } from "../../assets/img/sidebar/chevron-right.svg";
import { ReactComponent as ChevronUpIcon } from "../../assets/img/sidebar/chevron-up.svg";
import { ReactComponent as PhoneIcon } from "../../assets/img/sidebar/PhoneIcon.svg";
import { Divider, Grid, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

const drawerWidth = 264;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: "none",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  borderRight: "none",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const NavigationLink = (props: any) => {
  const { navItem, open, index, isHighlightable, userType } = props;
  const [expanded, setExpanded] = useState(false);
  const user = AuthHelpers.getUserTypeFromLocalStorage();
  const isAllowedUserPath = (navItem: any, user: string) =>
    navItem.allowedUserType.includes(user);
  const isAllowedUser = (navItem: any, userType: string) =>
    navItem.allowedUsers.includes(userType);
  const handleExpanded = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(!expanded);
  };
  useEffect(() => {
    if (open === false) {
      setExpanded(false);
    }
  }, [open]);

  return (
    <li className={`${!open && "hover:bg-brand-background-skyblue"}`}>
      <NavLink
        to={navItem.link}
        className={({ isActive }) =>
          `px-6 flex items-center py-3.5 justify-between group hover:bg-brand-background-skyblue ${
            isActive && isHighlightable ? "bg-brand-background-skyblue" : ""
          }`
        }
      >
        {({ isActive }) => {
          return (
            <React.Fragment key={navItem.drawerItemName + index}>
              <div className="flex">
                {navItem.icon && (
                  <>
                    <Tooltip title={navItem.drawerItemName}>
                      <div
                        className={`${
                          isActive
                            ? ` fill-brand-primary-blue stroke-brand-primary-blue`
                            : `fill-brand-text-primary stroke-brand-text-primary`
                        }  group-hover:fill-brand-primary-blue group-hover:stroke-brand-primary-blue`}
                      >
                        <navItem.icon />
                      </div>
                    </Tooltip>
                  </>
                )}
                <span
                  className={`group-hover:text-brand-primary-blue ${
                    isActive
                      ? "text-brand-primary-blue"
                      : "text-brand-text-primary"
                  } ${open ? "block px-3" : "hidden"}`}
                >
                  {navItem.drawerItemName}
                </span>
              </div>
              {open &&
                !expanded &&
                navItem.subItems &&
                navItem.subItems.length > 0 && (
                  <button
                    onClick={handleExpanded}
                    className={`${
                      isActive
                        ? ` fill-brand-primary-blue stroke-brand-primary-blue`
                        : `fill-brand-text-primary stroke-brand-text-primary`
                    }  group-hover:fill-brand-primary-blue group-hover:stroke-brand-primary-blue`}
                  >
                    <ChevronRightIcon />
                  </button>
                )}
              {open &&
                expanded &&
                navItem.subItems &&
                navItem.subItems.length > 0 && (
                  <button
                    onClick={handleExpanded}
                    className={`${
                      isActive
                        ? ` fill-brand-primary-blue stroke-brand-primary-blue`
                        : `fill-brand-text-primary stroke-brand-text-primary`
                    }  group-hover:fill-brand-primary-blue group-hover:stroke-brand-primary-blue`}
                  >
                    <ChevronUpIcon />
                  </button>
                )}
            </React.Fragment>
          );
        }}
      </NavLink>
      {navItem.subItems &&
        navItem.subItems.length > 0 &&
        expanded &&
        navItem.subItems.map((subItem: any, index: number) => {
          return (
            user &&
            userType &&
            isAllowedUserPath(subItem, user) &&
            isAllowedUser(subItem, userType) && (
              <>
                <NavigationLink
                  open={open}
                  key={subItem.key}
                  navItem={subItem}
                  index={index}
                  isHighlightable={false}
                />
              </>
            )
          );
        })}
    </li>
  );
};

 function Sidebar(props: any) {
  const { open } = props;
  const user = AuthHelpers.getUserTypeFromLocalStorage();
  const userSelector = useSelector((state: any) => state.user);
  const userType = userSelector.data?.company_roles[0]?.name;
  const isAllowedUserPath = (navItem: any, user: string) =>
    navItem.allowedUserType.includes(user);
  const isAllowedUser = (navItem: any, userType: string) =>
    navItem.allowedUsers.includes(userType);
  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader></DrawerHeader>

      <ul className="flex justify-center flex-col">
        {sideBarConfig.map((navItem, index) => {
          {
            return (
              user &&
              userType &&
              isAllowedUserPath(navItem, user) &&
              isAllowedUser(navItem, userType) && (
                <NavigationLink
                  key={navItem.id}
                  open={open}
                  navItem={navItem}
                  index={index}
                  isHighlightable={true}
                  userType={userType}
                />
              )
            );
          }
        })}
      </ul>
      <div className="px-8 mt-auto absolute bottom-0 bg-brand-primary-white w-full">
        <Divider sx={{ border:'1px solid #F5F8FF'}}/>
        <Grid container >
        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          display="flex"
          alignItems="center"
          className="py-4"
        >
          <PhoneIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-0" />
          <div>
            <p className="text-xs font-light text-brand-text-caption">
              Need Support? 
            </p>
            <p className="text-sm text-brand-text-title font-semibold">
              <a href="tel:+917290090309">+917290090309</a>
              
            </p>
          </div>
        </Grid>
        </Grid>
      </div>
    </Drawer>
  );
}

export default memo(Sidebar)
