import { createSlice, current } from "@reduxjs/toolkit";
import {
  getCompanies,
  getComapnyDetails,
  updateComapnyDetails,
  getCompanyUserRoles,
  getCompanyUsers,
  getCompanyInvitedUsers,
  getAllBillingEntities,
  getAllDeliveryAddresses,
  getAllSystemPermissions,
  addRolePermission,
  editRolePermission,
} from "./companyApi";

const initialState: any = {
  company: {
    data: null,
    status: "idle",
  },
  companyDetails: {
    data: null,
    status: "idle",
    update: false,
  },
  companyUser: {
    data: null,
    status: "idle",
  },
  addComapnyUser: {
    data: null,
    status: "idle",
  },
  companyUserRoles: {
    data: [],
    status: "idle",
  },
  companyPermission: {
    data: [],
    status: "idle",
  },
  users: {
    data: null,
    status: "idle",
  },
  invitedUsers: {
    data: null,
    status: "idle",
  },
  allBillingEntities: {
    data: null,
    status: "idle",
  },
  currentbillingEntities: {
    data: null,
    status: "idle",
  },
};

const companySlice = createSlice({
  initialState,
  name: "companySlice",
  reducers: {
    removeCompany: (state) => {
      state.companyDetails.status = "idle";
      state.companyDetails.data = null;
    },
    removeAddUser: (state) => {
      state.addComapnyUser.status = "idle";
      state.addComapnyUser.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanies.pending, (state, action) => {
      state.company.status = "pending";
    });
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.company.status = "succeeded";
      state.company.data = action.payload.data;
    });
    builder.addCase(getCompanies.rejected, (state, action) => {
      state.company.status = "failed";
      state.company.data = action.payload;
    });
    builder.addCase(getComapnyDetails.pending, (state, action) => {
      state.companyDetails.status = "pending";
      state.companyDetails.update = false;
    });
    builder.addCase(getComapnyDetails.fulfilled, (state, action) => {
      state.companyDetails.status = "succeeded";
      state.companyDetails.data = action.payload;
      state.companyDetails.update = false;
    });
    builder.addCase(getComapnyDetails.rejected, (state, action) => {
      state.companyDetails.status = "failed";
      state.companyDetails.data = action.error.message;
      state.companyDetails.update = false;
    });
    builder.addCase(updateComapnyDetails.pending, (state, action) => {
      state.companyDetails.status = "pending";
      state.companyDetails.update = false;
    });
    builder.addCase(updateComapnyDetails.fulfilled, (state, action) => {
      state.companyDetails.status = "succeeded";
      state.companyDetails.data = action.payload;
      state.companyDetails.update = true;
    });
    builder.addCase(updateComapnyDetails.rejected, (state, action) => {
      state.companyDetails.status = "failed";
      state.companyDetails.data = action.error.message;
      state.companyDetails.update = false;
    });
    builder.addCase(getCompanyUserRoles.pending, (state, action) => {
      state.companyUserRoles.status = "pending";
    });
    builder.addCase(getCompanyUserRoles.fulfilled, (state, action: any) => {
      state.companyUserRoles.status = "succeeded";
      state.companyUserRoles.data = action.payload?.data;
    });
    builder.addCase(getCompanyUserRoles.rejected, (state, action) => {
      state.companyUserRoles.status = "failed";
      state.companyUserRoles.data = action.error.message;
    });
    builder.addCase(getCompanyUsers.pending, (state, action) => {
      state.users.status = "pending";
    });
    builder.addCase(getCompanyUsers.fulfilled, (state, action) => {
      state.users.status = "succeeded";
      state.users.data = action.payload;
    });
    builder.addCase(getCompanyUsers.rejected, (state, action) => {
      state.users.status = "failed";
      state.users.data = action.error.message;
    });
    builder.addCase(getCompanyInvitedUsers.pending, (state, action) => {
      state.invitedUsers.status = "pending";
    });
    builder.addCase(getCompanyInvitedUsers.fulfilled, (state, action) => {
      state.invitedUsers.status = "succeeded";
      state.invitedUsers.data = action.payload;
    });
    builder.addCase(getCompanyInvitedUsers.rejected, (state, action) => {
      state.invitedUsers.status = "failed";
      state.invitedUsers.data = action.error.message;
    });
    builder.addCase(getAllBillingEntities.pending, (state, action) => {
      state.allBillingEntities.status = "pending";
    });
    builder.addCase(getAllBillingEntities.fulfilled, (state, action) => {
      state.allBillingEntities.status = "succeeded";
      state.allBillingEntities.data = action.payload;
    });
    builder.addCase(getAllBillingEntities.rejected, (state, action) => {
      state.allBillingEntities.status = "failed";
      state.allBillingEntities.data = action.error.message;
    });

    // builder.addCase(getAllDeliveryAddresses.pending,(state, action) => {
    //   state.allDeliveryAddresses.status = "pending";
    // });
    // builder.addCase(getAllDeliveryAddresses.fulfilled,(state, action) => {
    //   state.allDeliveryAddresses.status = "succeeded";
    //   state.allDeliveryAddresses.data = action.payload;
    // });
    // builder.addCase(getAllDeliveryAddresses.rejected,(state, action) => {
    //   state.allDeliveryAddresses.status = "failed";
    //   state.allDeliveryAddresses.data = action.error.message;
    // });
    builder.addCase(getAllSystemPermissions.pending, (state, action) => {
      state.companyPermission.status = "pending";
    });
    builder.addCase(getAllSystemPermissions.fulfilled, (state, action) => {
      state.companyPermission.status = "succeeded";
      state.companyPermission.data = action.payload?.data;
    });
    builder.addCase(getAllSystemPermissions.rejected, (state, action) => {
      state.companyPermission.status = "failed";
      state.companyPermission.data = action.error.message;
    });
    builder.addCase(addRolePermission.pending, (state, action) => {
      state.companyUserRoles.status = "pending";
    });
    builder.addCase(addRolePermission.fulfilled, (state, action) => {
      state.companyUserRoles.status = "succeeded";
      state.companyUserRoles.data = [
        ...current(state).companyUserRoles.data,
        action.payload,
      ];
    });
    builder.addCase(addRolePermission.rejected, (state, action) => {
      state.companyUserRoles.status = "failed";
      // state.companyUserRoles.data = action.error.message;
    });
    builder.addCase(editRolePermission.pending, (state, action) => {
      state.companyUserRoles.status = "pending";
    });
    builder.addCase(editRolePermission.fulfilled, (state, action: any) => {
      state.companyUserRoles.status = "succeeded";
      console.log(action.payload, current(state)
      .companyUserRoles.data?.map((a: any) => a.id)
      );
      const index = current(state)
        .companyUserRoles.data?.map((a: any) => a.id)
        ?.indexOf(action.payload.id);
      if (index > -1) {
        // only splice array when item is found
        const newArr = [...current(state).companyUserRoles.data];
        //change when api will be corrected
        newArr?.splice(index, 1, action.payload);
        state.companyUserRoles.data = [...newArr];
      }
    });
    builder.addCase(editRolePermission.rejected, (state, action) => {
      state.companyUserRoles.status = "failed";
      //state.companyUserRoles.data = action.error.message;
    });
  },
});
export const { removeCompany, removeAddUser } = companySlice.actions;
export default companySlice.reducer;
