import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { downloadURI } from "../../../utils/common";
import { API_DOMAIN } from "../../../utils/constants/apiConfig";
import { API_URLS } from "../../../utils/constants/apiUrls";
import axiosInstance from "../../../utils/interceptor";

export const getAllRFQ = createAsyncThunk(
  "getAllRFQ",
  async (data: { user: string; c_uuid: string; query?: any }) => {
    const result = await axiosInstance.get(`${API_URLS[data.user].getAllRFQ}`, {
      params: {
        c_uuid: data.c_uuid,
        ...data.query,
      },
    });
    return result;
  }
);

export const getRFQ = createAsyncThunk(
  "getRFQ",
  async (data: { user: string; c_uuid: string; rfqId: string }) => {
    const result = await axiosInstance.get(
      `${API_URLS[data.user].getRfq(data.rfqId)}`,
      { params: { c_uuid: data.c_uuid } }
    );
    return result;
  }
);
export const createRFQ = createAsyncThunk(
  "createRFQ",
  async (data: { user: string; c_uuid: string; data: any }) => {
    const result = await axiosInstance.post(
      `${API_URLS[data.user].createRFQ}`,
      data.data
    );
    return result;
  }
);
export const updateRFQ = createAsyncThunk(
  "updateRFQ",
  async (data: { user: string; c_uuid: string; rfqId: string; data: any }) => {
    const result = await axiosInstance.put(
      `${API_URLS[data.user].updateRFQ(data.rfqId)}`,
      data.data
    );
    return result;
  }
);
export const uploadRFQ = createAsyncThunk(
  "uploadRFQ",
  async (data: { user: string; c_uuid: string; rfqId: string; data: any }) => {
    const token = localStorage.getItem("token");
    if (token) {
      const result = await axios.post(
        `${API_URLS[data.user].uploadProduct(data.rfqId, data.c_uuid)}`,
        data.data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            c_uuid: data.c_uuid,
          },
        }
      );
      return result;
    }
  }
);

export const getRfqProducts = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
  query?: any;
}) => {
  if (API_URLS[data.user] && API_URLS[data.user]?.listProducts(data.rfqId)) {
    const result = axiosInstance.get(
      `${API_URLS[data.user]?.listProducts(data.rfqId)}`,
      { params: { c_uuid: data.c_uuid, ...data.query } }
    );
    return result;
  }
};

export const createRfqProducts = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
  data: any;
}) => {
  if (API_URLS[data.user] && API_URLS[data.user]?.listProducts(data.rfqId)) {
    const result = axiosInstance.post(
      `${API_URLS[data.user]?.addProduct(data.rfqId)}`,
      data.data
    );
    return result;
  }
};

export const updateRfqProduct = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
  rfq_product_uuid: string;
  data: any;
}) => {
  if (API_URLS[data.user] && API_URLS[data.user]?.listProducts(data.rfqId)) {
    const result = axiosInstance.put(
      `${API_URLS[data.user]?.updateProduct}/${data.rfqId}/${
        data.rfq_product_uuid
      }`,
      data.data
    );
    return result;
  }
};

export const getRFQTemplate = createAsyncThunk(
  "getTemplate",
  async (data: { user: string; c_uuid: string }) => {
    const result = await axiosInstance.get(
      `${API_URLS[data.user].getAllRFQTemplates}${data.c_uuid}`
    );
    return result;
  }
);

export const releaseRfq = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
}) => {
  const result = await axiosInstance.post(
    `${API_URLS[data.user].releaseRFQ}/${data.rfqId}/release`,
    { c_uuid: data.c_uuid }
  );
  return result;
};

export const getQuotaionsByRfqId = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
}) => {
  if (API_URLS[data.user] && API_URLS[data.user].getQuotationByRfqId) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user].getQuotationByRfqId}/${data.rfqId}/requests`,
      { params: { c_uuid: data.c_uuid } }
    );

    return result;
  }
};

export const getSupplierRequestsByRfqId = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
}) => {
  if (API_URLS[data.user] && API_URLS[data.user].getSupplierRequestsRfqId) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user].getSupplierRequestsRfqId}/${data.rfqId}/requests`,
      { params: { c_uuid: data.c_uuid } }
    );

    return result;
  }
};

export const getQuotationbyrequestId = async (data: {
  user: string;
  c_uuid: string;
  requestId: string;
  quotationId: string;
}) => {
  if (API_URLS[data.user]?.getQuotationDetailsForBuyer) {
    const result = await axiosInstance.get(
      `${API_URLS[data.user].getQuotationDetailsForBuyer}/${
        data.requestId
      }/quotation/${data.quotationId}`,
      { params: { c_uuid: data.c_uuid } }
    );

    return result;
  }
};

export const downloadQuotationbyQuotationIdForBuyer = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
  quotationId: string;
}) => {
  if (API_URLS[data.user]?.downloadQuotationForBuyer) {
    const response = await axiosInstance.get(
      `${API_URLS[data.user].downloadQuotationForBuyer(
        data.rfqId,
        data.quotationId
      )}`,
      { params: { c_uuid: data.c_uuid }, responseType: "blob" }
    );
    let fileName =
      response?.headers["content-disposition"].split("filename=")[1] ||
      "Unknown";
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    // Trigger Download URI for the file
    downloadURI(url, fileName);

    return response;
  }
};

export const downloadQuotationbyQuotationIdForSupplier = async (data: {
  user: string;
  c_uuid: string;
  rfqRequestId: string;
  quotationId: string;
  forUpload: boolean
}) => {
  if (API_URLS[data.user]?.downloadQuotationForSupplier) {
    const response = await axiosInstance.get(
      `${API_URLS[data.user].downloadQuotationForSupplier(
        data.rfqRequestId,
        data.quotationId
      )}`,
      { params: { c_uuid: data.c_uuid, forUpload: data.forUpload }, responseType: "blob" }
    );
    let fileName =
      response?.headers["content-disposition"].split("filename=")[1] ||
      "Unknown";
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    // Trigger Download URI for the file
    downloadURI(url, fileName);

    return response;
  }
};

export const getComparePrice = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
}) => {
  const result = await axiosInstance.get(
    `${API_DOMAIN}/api/v1/rfq/buyer/${data.rfqId}/quotations/compare`,
    { params: { c_uuid: data.c_uuid } }
  );
  return result;
};

// const type = {
//   auto,
//   single,
//   rfq_request_quotation_uuid,
// };
export const getRecommandationForReview = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
  type: string;
  criteria?: string;
  quotationId?: string;
}) => {
  const result = await axiosInstance.get(
    API_URLS[data.user].getRecommandationForReview(data.rfqId),
    {
      params: {
        c_uuid: data.c_uuid,
        type: data.type,
        criteria: data.criteria || undefined,
        rfq_quotation_id: data.quotationId || undefined,
      },
    }
  );
  return result;
};

export const downloadRecommendationQuotation = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
  recommendationId: string;
  quotationId: string;
}) => {
  if (API_URLS[data.user]?.downloadRecommandationQuotation) {
    const response = await axiosInstance.get(
      `${API_URLS[data.user].downloadRecommandationQuotation(
        data.rfqId,
        data.recommendationId,
        data.quotationId
      )}`,
      { params: { c_uuid: data.c_uuid }, responseType: "blob" }
    );
    let fileName =
      response?.headers["content-disposition"].split("filename=")[1] ||
      "Unknown";
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/pdf",
      })
    );

    // Trigger Download URI for the file
    downloadURI(url, fileName);

    return response;
  }
};

export const extendSubmissionDate = async (data: {
  user: string;
  c_uuid: string;
  rfqId: string;
  data: any;
}) => {
  const result = await axiosInstance.patch(
    API_URLS[data.user].extendSubmission(data.rfqId),
    { c_uuid: data.c_uuid, ...data.data }
  );
};


export const getAllQuotations =async (data: {
  user: string;
  c_uuid: string;
}) => {
  const result = await axiosInstance.get(
    API_URLS[data.user].getAllRfqRequests,
    {
      params: {
        c_uuid: data.c_uuid,
      },
    }
  );
  return result
}

export const getDashBoardData = async (data: {
  user: string;
  c_uuid: string;
}) => {
  const result = await axiosInstance.get(
    API_URLS[data.user].getDashboard,
    {
      params: {
        c_uuid: data.c_uuid,
      },
    }
  );
  return result
}
