import React, { useEffect } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { capitalize } from "../../utils/common";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useCommonStyles } from "../../styles/common";
import CommonButton from "../Button";
import { Grid, Link, Checkbox } from "@mui/material";
import Input from "../Input/MaterialInput";
import Dropdown from "../Dropdown";

interface Data {
  updated_at: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headerCells: any;
  inputCells: any;
  actions: Array<object>;
}

const customTableCellStyles = (maxWidth?: string) => {
  return {
    fontFamily: "Public Sans",
    maxWidth: maxWidth ?? "8rem",
    wordBreak: "break-word",
    wordWrap: "break-word",
  };
};

const customActionsHeadCellStyles = (maxWidth?: string) => {
  return {
    fontFamily: "Public Sans",
    position: "sticky",
    maxWidth: maxWidth ?? "8rem",
    right: "0",
    fontWeight: "600",
    zIndex: "1300",
    background: "#EDF6FF",
  };
};

const customActionsCellStyles = (maxWidth?: string) => {
  return {
    fontFamily: "Public Sans",
    minWidth: "4rem",
    maxWidth: maxWidth ?? "8rem",
    position: "sticky",
    right: "0",
    fontWeight: "600",
    zIndex: "1300",
    background: "#ffffff",
  };
};

const CustomTableCell = ({
  row,
  name,
  onChange,
  index,
  inputType,
  onBlur,
  options,
}: any) => {
  return (
    <TableCell
      align="left"
      component="td"
      id={row.id}
      key={`${row.id}-label-${index}`}
      scope="row"
      padding={"normal"}
      sx={customTableCellStyles(row.maxWidth)}
    >
      {inputType === "checkbox" ? (
        <Checkbox
          name={name}
          id={row.id}
          key={`${row.id}-label-${index}`}
          color="primary"
          value={row[name]}
          //indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={row[name] ? true : false}
          onChange={(e) => onChange(e, row)}
          inputProps={{
            "aria-label": "select all desserts",
          }}
          size="small"
          sx={{
            color: "#9DACB4",
          }}
        />
      ) : inputType === "dropdown" ? (
        <Dropdown
          id={name}
          type={inputType}
          options={options ?? []}
          value={row[name] === 0 ? "0" : row[name] || ""}
          name={name}
          onChange={(e: any) => onChange(e, row)}
          onBlur={(e: any) => {
            onBlur && onBlur(e, row);
          }}
        />
      ) : (
        <Input
          type={inputType}
          value={row[name] === 0 ? "0" : row[name] || ""}
          name={name}
          onChange={(e: any) => onChange(e, row)}
          onBlur={(e: any) => {
            onBlur && onBlur(e, row);
          }}
        />
      )}
    </TableCell>
  );
};

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headerCells,
    inputCells,
    actions,
  } = props;

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const checkIfCustomCellExist = () => {
    return headerCells.find((i: any) => i.customCell);
  };

  return (
    <TableHead className="bg-brand-background-skyblue relative">
      <TableRow>
        {!checkIfCustomCellExist() && (
          <TableCell align={"left"} padding={"normal"}>
            <p className="text-brand-text-tableHead pl-8 font-semibold">S.no</p>
          </TableCell>
        )}
        {headerCells.map((headCell: any, i: number) => (
          <React.Fragment key={`headcell${i}-${headCell.label}`}>
            {headCell.customCell && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  size="small"
                  sx={{
                    color: "#9DACB4",
                  }}
                />
              </TableCell>
            )}
            <TableCell
              key={headCell.id + "x"}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={customTableCellStyles(headCell.maxWidth)}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <p className="text-brand-text-tableHead font-semibold whitespace-normal w-32  ">
                  {headCell.label && capitalize(headCell.label)}
                </p>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </React.Fragment>
        ))}
        {inputCells?.map((headCell: any, i: number) => (
          <React.Fragment key={`headcell${i}-${headCell.label}`}>
            {headCell.customCell && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  size="small"
                  sx={{
                    color: "#9DACB4",
                  }}
                />
              </TableCell>
            )}
            <TableCell
              key={headCell.id + "x"}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={customActionsHeadCellStyles(headCell.maxWidth)}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <p className="text-brand-text-tableHead whitespace-normal w-24  ">
                  {headCell.label && capitalize(headCell.label)}
                </p>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </React.Fragment>
        ))}
        {actions && actions.length ? (
          <TableCell
            key={"hjcvdvsc"}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            className="text-brand-text-title "
            sx={customActionsHeadCellStyles()}
          >
            Actions
          </TableCell>
        ) : (
          <></>
        )}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

/**
 *
 * @param props numSelected
 * @returns Toolbar at the top if any element is selected with check box
 */
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <>
      {numSelected > 0 && (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
        >
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            <p className="text-brand-text-title font-dm_sans">
              {numSelected} selected
            </p>
          </Typography>
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      )}
    </>
  );
};

interface HeadCell {
  disablePadding?: boolean;
  id?: keyof Data;
  label?: string;
  numeric?: boolean;
  callBackArguments?: Array<string>;
  customCell?: any;
}
interface Props {
  rows: any[];
  inputColumns?: any[];
  columns?: any;
  actions?: any[];
  onClickFunc?: any;
  text?: string;
  stroke?: any;
  image?: any;
  description?: string;
  headingText?: string;
  onPageChange?: any;
  meta?: any;
  status?: string | undefined;
  scrollFix?: boolean;
  tableContainerStyles?: any;
  onChange?: (e: any, row: any) => void;
  onBlur?: (e: any, row: any) => void;
  onSortChange?: (sort: string, sortBy: string) => void;
}
/**
 *
 * @param props (rows, columns, actions)
 * @returns Customized table based on props
 */
const EnhancedTable: React.FC<Props> = (props) => {
  const {
    rows,
    columns,
    actions,
    onClickFunc,
    text,
    stroke,
    image,
    description,
    headingText,
    onPageChange,
    meta,
    status,
    inputColumns,
    scrollFix,
    tableContainerStyles,
    onChange,
    onBlur,
    onSortChange,
  } = props;
  const classes = useCommonStyles();
  const [order, setOrder] = React.useState<any>("");
  const [orderBy, setOrderBy] = React.useState<any>("");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const compoRef = React.useRef<HTMLDivElement>(null);
  //const [rows, setRows] = React.useState(props.rows);
  const [previous, setPrevious] = React.useState<any>({});
  const handleClickAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setPage(meta?.currentPage ? meta?.currentPage - 1 : 0);
    meta?.perPage && setRowsPerPage(meta?.perPage);
  }, [meta]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    onSortChange && onSortChange(isAsc ? "DESC" : "ASC", property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n.name);
      newSelecteds && setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const getIntoView = () => {
    if (compoRef.current) {
      compoRef?.current?.scrollIntoView();
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onPageChange(newPage + 1, rowsPerPage, status);
    getIntoView();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onPageChange(page + 1, parseInt(event.target.value, 10), status);
    getIntoView();
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const getCustomCell = () => {
    if (columns.find((i: any) => i.customCell)) return true;
    return false;
  };

  const onSelectActions = (action: any, row: any) => {
    if (!action?.callbackArguments?.length) {
      action?.onClick && action?.onClick(row);
    } else if (action?.callbackArguments?.length === 1) {
      action.onClick(row[action?.callbackArguments[0]]);
    } else if (action?.callbackArguments?.length === 2) {
      action.onClick(
        row[action?.callbackArguments[0]],
        row[action?.callbackArguments[1]]
      );
    } else if (action?.callbackArguments?.length === 3) {
      action.onClick(
        row[action?.callbackArguments[0]],
        row[action?.callbackArguments[1]],
        row[action?.callbackArguments[2]]
      );
    } else if (action?.callbackArguments?.length === 4) {
      action.onClick(
        row[action?.callbackArguments[0]],
        row[action?.callbackArguments[1]],
        row[action?.callbackArguments[2]],
        row[action?.callbackArguments[3]]
      );
    }
    handleClose();
  };

  return (
    <Box
      sx={{ width: "100%", overflow: "hidden" }}
      ref={compoRef}
      className="table_container_common"
    >
      <Paper
        sx={{ width: "100%", mb: 2, boxShadow: "none", overflow: "hidden" }}
        className={`shadow-card border-brand-extra-divider border ${
          stroke ? "border border-brand-extra-border" : ""
        }`}
      >
        <TableContainer
          className={`shadow-card ${
            tableContainerStyles ? tableContainerStyles : ""
          } ${scrollFix ? "always-scrollbar" : ""}`}
        >
          <EnhancedTableToolbar numSelected={selected.length} />
          <Table
            sx={{ border: `1px solid #EDF6FF`, overflow: "scroll" }}
            // sx={{ minWidth: 1200 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order ?? ""}
              orderBy={orderBy ?? ""}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headerCells={columns}
              inputCells={inputColumns}
              actions={actions || []}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {rows.map((row: any, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `${row.id}`;

                return (
                  <TableRow
                    // hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id + index}
                    selected={isItemSelected}
                  >
                    {getCustomCell() ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row.name)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          className="border-brand-extra-border"
                          size="small"
                          sx={{
                            color: "#9DACB4",
                          }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell sx={customTableCellStyles()}>
                        <p className="text-brand-text-title pl-8">
                          {rowsPerPage && page
                            ? index + 1 + rowsPerPage * page
                            : index + 1}
                        </p>
                      </TableCell>
                    )}
                    {columns.map((i: any, index: any) => (
                      <TableCell
                        component="td"
                        id={labelId}
                        key={`${labelId}-label-${index}`}
                        scope="row"
                        padding={"normal"}
                        align="left"
                        title={row[i.id]}
                        sx={customTableCellStyles(row[i.maxWidth])}
                      > 
                        {i.makeLinkable ? (
                          <a
                            href=""
                            onClick={() =>
                              i.onClickAction &&
                              i.onClickAction(row[i.callBackArguments[0]])
                            }
                            className="text-brand-primary-blue cursor-pointer	"
                          >
                            {row[i.id]}
                          </a>
                        ) : (
                          <p className="text-brand-text-primary">
                            {i.getCustomColumn ? i.getColData(row) : row[i.id]}
                          </p>
                        )}
                      </TableCell>
                    ))}
                    {inputColumns?.map((i: any, index: number) => {
                      return (
                        <CustomTableCell
                          {...{
                            row,
                            name: `${i.id}`,
                            onChange,
                            onBlur,
                            index,
                            inputType: i.inputType,
                            options: i.options ?? [],
                          }}
                        />
                      );
                    })}
                    {actions && actions.length > 1 ? (
                      <TableCell
                        component="td"
                        id={"cgvdhcvhd"}
                        scope="row"
                        padding="normal"
                        align="left"
                        className="text-brand-text-tableHead  font-punlic_sans "
                        sx={customActionsCellStyles()}
                      >
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment key={"popper"}>
                              {/* <IconButton 
                              style={{padding:'0'}} {...bindTrigger(popupState)}>
                                <MoreHorizIcon className="text-brand-extra-icon1" fontSize="medium"/>
                              </IconButton> */}
                              <button
                                className="text-brand-primary-blue w-20 font-semibold"
                                style={{ padding: "0" }}
                                {...bindTrigger(popupState)}
                              >
                                {"Action"}
                                <ArrowDropDownIcon color="primary" />
                              </button>

                              <Menu
                                {...bindMenu(popupState)}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                  className: "shadow-card",
                                }}
                                style={{ boxShadow: "none" }}
                              >
                                {actions.map((i: any) => {
                                  return (
                                    <MenuItem
                                      sx={{ padding: "0" }}
                                      disabled={i?.disabled && i.disabled(row)}
                                      key={`menu-${
                                        i?.customLabel
                                          ? i?.customLabel(row)
                                          : i.label
                                      }}`}
                                    >
                                      {!i?.hidden?.value?.includes(
                                        row[i.hidden.key]
                                      ) && (
                                        <div
                                          className="w-full px-4 py-2"
                                          onClick={() => {
                                            onSelectActions(i, row);
                                            popupState.close();
                                          }}
                                        >
                                          <p
                                            className={
                                              "font-dm_sans text-sm" +
                                              `${
                                                i.id === "delete"
                                                  ? " text-brand-primary-red"
                                                  : " text-brand-text-title"
                                              }`
                                            }
                                          >
                                            {i?.customLabel
                                              ? i?.customLabel(row)
                                              : i.label}
                                          </p>
                                        </div>
                                      )}
                                    </MenuItem>
                                  );
                                })}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    ) : (
                      <></>
                    )}

                    {actions && actions.length == 1 ? (
                      <TableCell
                        component="td"
                        id={"cgvdhcvhd"}
                        scope="row"
                        padding="normal"
                        align="left"
                        className="text-brand-text-tableHead  font-punlic_sans "
                        sx={customActionsCellStyles()}
                      >
                        <div
                          className="w-full"
                          onClick={() => {
                            onSelectActions(actions[0], row);
                          }}
                        >
                          <p
                            className={
                              "font-dm_sans text-sm text-brand-primary-blue font-semibold cursor-pointer " +
                              `${
                                actions[0].id === "delete"
                                  ? " text-brand-primary-red"
                                  : " text-brand-text-title"
                              }`
                            }
                          >
                            {actions[0]?.customLabel
                              ? actions[0]?.customLabel(row)
                              : actions[0].label}
                          </p>
                        </div>
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                );
              })}
              {rows.length === 0 && (
                <TableRow
                  style={{
                    height: 100,
                  }}
                >
                  <TableCell
                    colSpan={12}
                    align="center"
                    style={{ padding: "2rem" }}
                  >
                    <img
                      src={
                        image ??
                        require("../../assets/img/rfq/datagrid.svg").default
                      }
                      className="mx-auto mt-4"
                      width={"64px"}
                      height={"64px"}
                    />

                    <h5 className="mt-4 text-brand-text-title text-xl font-semibold">
                      {headingText ?? "No Data Found"}
                    </h5>

                    {description ? (
                      <p className="text-base text-brand-text-primary mt-2 mb-2">
                        {description}
                      </p>
                    ) : (
                      <></>
                    )}
                    {text ? (
                      <Grid
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{ maxWidth: "initial" }}
                      >
                        <Grid
                          item
                          lg={2}
                          md={4}
                          xs={12}
                          className={classes.mpt5}
                          style={{ maxWidth: "initial" }}
                        >
                          <CommonButton text={text} onClick={onClickFunc} />
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {meta && (
          <TablePagination
            rowsPerPageOptions={
              page + 1 > meta?.total / rowsPerPage ? [] : [5, 10, 20, 25, 50]
            }
            component="div"
            count={meta?.total || rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={"text-brand-text-title bg-white"}
            style={{ overflow: "hidden" }}
          />
        )}
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
};

export default EnhancedTable;
